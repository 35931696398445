export let db;
export const initializeDatabase = () => {
  return new Promise((resolve, reject) => {
    let request = indexedDB.open('myDatabase', 1);

    request.onerror = function (event) {
      console.error('Error opening the database', event);
      reject(event);
    };

    request.onsuccess = function (event) {
      db = event.target.result;
      resolve(db);
    };

    request.onupgradeneeded = function (event) {
      let db = event.target.result;
      db.createObjectStore('cache', { keyPath: 'key' });
    };
  });
};
