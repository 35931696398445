import step0 from 'assets/img/hodlie/guides/convert/0.jpg';
import step1 from 'assets/img/hodlie/guides/convert/1.jpg';
import step2 from 'assets/img/hodlie/guides/convert/2.jpg';
import step3 from 'assets/img/hodlie/guides/convert/3.jpg';
import step4 from 'assets/img/hodlie/guides/convert/4.jpg';
import step5 from 'assets/img/hodlie/guides/convert/5.png';
import step6 from 'assets/img/hodlie/guides/convert/6.jpg';
// mobile img
import m_step1 from 'assets/img/hodlie/guides/convert/mobile/1.jpg';
import m_step2 from 'assets/img/hodlie/guides/convert/mobile/2.jpg';
import m_step3a from 'assets/img/hodlie/guides/convert/mobile/3a.jpg';
import m_step3b from 'assets/img/hodlie/guides/convert/mobile/3b.jpg';
import m_step3c from 'assets/img/hodlie/guides/convert/mobile/3c.jpg';
import m_step4a from 'assets/img/hodlie/guides/convert/mobile/4a.jpg';
import m_step4b from 'assets/img/hodlie/guides/convert/mobile/4b.jpg';

export const binanceConvertGuide = {
  title: {
    'en-EN': 'Guide to Converting Your Assets on Binance',
    'it-IT': 'Guida alla conversione dei tuoi asset su Binance'
  },
  titleDescription: {
    'en-EN': `Before proceeding with the conversion, make sure that the funds to be converted are not already allocated on Hodlie to prevent your active bots from going into error.
    To check how many unallocated assets you have available on Binance, go to the <a href='/wallet'>Wallet</a> section of Hodlie and refer to the table called <b>"Available on Binance"</b>.
    These are the assets you can convert without interfering with the operation of your bots on Hodlie.
    <br><br>
    <img src="${step0}" alt="1" width="100%">
    `,
    'it-IT': `Prima di procedere alla conversione, assicurati che i fondi da convertire non siano già allocati su Hodlie, per evitare che i tuoi bot attivi vadano in errore. 
    Per verificare quanti asset non allocati hai a disposizione su Binance, dirigiti alla sezione <a href='/wallet'>Wallet</a> di Hodlie e fai riferimento alla tabella denominata <b>“Disponibile su Binance”</b>. 
    Questi sono gli asset che puoi convertire, senza interferire con l’operatività dei tuoi bot su Hodlie.
    <br><br>
    <img src="${step0}" alt="1" width="100%">
    `
  },
  lastUpdate: {
    'it-IT': 'Ultimo aggiornamento: ',
    'en-EN': 'Last update: '
  },
  sections: [
    {
      header: {
        'en-EN': 'GUIDE FROM PC',
        'it-IT': 'GUIDA DA PC'
      },
      name: {
        'en-EN':
          'Access your profile on Binance, click on your <b>Profile</b> and select <b>Assets</b> in the dropdown menu',
        'it-IT':
          'Accedi al tuo profilo su Binance, clicca sul tuo <b>Profilo</b> e seleziona <b>Asset</b> nel menù a tendina'
      },
      description: {
        'en-EN': `<img src="${step1}" alt="1" width="100%">`,
        'it-IT': `<img src="${step1}" alt="1" width="100%">`
      },
      shortName: {
        'en-EN': 'n1',
        'it-IT': 'n1'
      }
    },
    {
      name: {
        'en-EN':
          'At this point, select <b>Spot</b> from the menu that appears on the left.',
        'it-IT':
          'A questo punto seleziona <b>Spot</b> nel menù che ti appare a sinistra.'
      },
      description: {
        'en-EN': `<img src="${step2}" alt="1" width="100%">`,
        'it-IT': `<img src="${step2}" alt="1" width="100%">`
      },
      shortName: {
        'en-EN': 'n2',
        'it-IT': 'n2'
      }
    },
    {
      name: {
        'en-EN': `Inside this section, you will see a general overview of your Spot portfolio, including the distribution of all your assets. 
        Select the asset you want to convert and click on <b>Convert</b>.
        In this case, we will convert EUR, but the operation can be performed with any asset.`,
        'it-IT': `All’interno di questa sezione potrai vedere un’overview generale del tuo portafoglio Spot, con la distribuzione di tutti i tuoi asset. 
        Seleziona l’asset che vuoi convertire, e clicca su <b>
        Converti</b>. 
        In questo caso, convertiremo EUR, ma è possibile effettuare l’operazione con qualsiasi asset. `
      },
      description: {
        'en-EN': `<img src="${step3}" alt="1" width="100%">`,
        'it-IT': `<img src="${step3}" alt="1" width="100%">`
      },
      shortName: {
        'en-EN': 'n3',
        'it-IT': 'n3'
      }
    },
    {
      name: {
        'en-EN': `Select the Currency into which you want to convert your asset, a dropdown menu will open.
          In this case, we choose how many EUR to convert and select SOL.`,
        'it-IT': `Seleziona la Valuta in cui vuoi convertire il tuo asset, si aprirà un menù a tendina. 
          In questo caso, scegliamo quanti EUR convertire e selezioniamo SOL.`
      },
      description: {
        'en-EN': `<img src="${step4}" alt="1" width="100%"><br><br>
        <img src="${step5}" alt="1" width="100%">`,
        'it-IT': `<img src="${step4}" alt="1" width="100%"><br><br>
        <img src="${step5}" alt="1" width="100%">`
      },
      shortName: {
        'en-EN': 'n4',
        'it-IT': 'n4'
      }
    },
    {
      name: {
        'en-EN': `Confirm the conversion. Now you're ready to activate your bot on Hodlie!`,
        'it-IT': `Conferma la conversione. Ora sei pronto ad attivare il tuo bot su Hodlie!`
      },
      description: {
        'en-EN': `<img src="${step6}" alt="1" width="100%">`,
        'it-IT': `<img src="${step6}" alt="1" width="100%">`
      },
      shortName: {
        'en-EN': 'n5',
        'it-IT': 'n5'
      }
    }
  ],
  sectionsMobile: [
    {
      header: {
        'en-EN': 'GUIDE FROM MOBILE',
        'it-IT': 'GUIDA DA MOBILE'
      },
      name: {
        'en-EN': '',
        'it-IT':
          'Accedi alla Home di Binance e clicca su <b>Scambi</b> nel menù che trovi in basso '
      },
      description: {
        'en-EN': ``,
        'it-IT': `<img src="${m_step1}" alt="1" style="width:${
          window.innerWidth <= 768 ? `100%` : `30%`
        };">`
      },
      shortName: {
        'en-EN': 'n1',
        'it-IT': 'n1'
      }
    },
    {
      name: {
        'en-EN': '',
        'it-IT': 'A questo punto seleziona <b>Converti<b> in alto a sinistra '
      },
      description: {
        'en-EN': ``,
        'it-IT': `<img src="${m_step2}" alt="1" style="width:${
          window.innerWidth <= 768 ? `100%` : `30%`
        };">`
      },
      shortName: {
        'en-EN': 'n2',
        'it-IT': 'n2'
      }
    },
    {
      name: {
        'en-EN': ``,
        'it-IT': `Seleziona la Valuta in cui vuoi convertire il tuo asset, si aprirà un menù a tendina. 
        In questo caso, scegliamo quanti EUR convertire e selezioniamo SOL.
        `
      },
      description: {
        'en-EN': ``,
        'it-IT': `<img src="${m_step3a}" alt="1" style="width:${
          window.innerWidth <= 768 ? `100%` : `30%`
        };"><br><br>
        <img src="${m_step3b}" alt="1" style="width:${
          window.innerWidth <= 768 ? `100%` : `30%`
        };"><br><br>
        N.B. Per sapere quanti asset hai a disposizione, clicca su Wallet nel menù in basso a destra<br><br>
        <img src="${m_step3c}" alt="1" style="width:${
          window.innerWidth <= 768 ? `100%` : `30%`
        };">
        `
      },
      shortName: {
        'en-EN': 'n3',
        'it-IT': 'n3'
      }
    },
    {
      name: {
        'en-EN': ``,
        'it-IT': `Clicca su <b>Anteprima della conversione</b> e poi su <b>Converti</b>. Ora sei pronto ad attivare il tuo bot su Hodlie!`
      },
      description: {
        'en-EN': ``,
        'it-IT': `<img src="${m_step4a}" alt="1" style="width:${
          window.innerWidth <= 768 ? `100%` : `30%`
        };"><br><br>
        <img src="${m_step4b}" alt="1" style="width:${
          window.innerWidth <= 768 ? `100%` : `30%`
        };"><br><br>`
      },
      shortName: {
        'en-EN': 'n4',
        'it-IT': 'n4'
      }
    }
  ]
};
