export const binanceDepositGuide = {
  title: {
    'en-EN': 'Binance Deposit Guide (updated on 26/01/24)',
    'it-IT': 'Guida al deposito su Binance (aggiornata al 26/01/24)'
  },
  titleDescription: {
    'en-EN':
      'This guide provides instructions for depositing your funds on Binance. Please note that the guide is constantly updated, and the deposit methods are subject to frequent changes by Binance itself. <br>This guide is therefore intended as a useful tool for Hodlie users to make their experience as easy as possible.',
    'it-IT':
      'Questa guida fornisce istruzioni per depositare i propri fondi su Binance. Si prega di notare che la guida è in costante aggiornamento, e che i metodi di deposito subiscono spesso modifiche da parte di Binance stesso. <br>Questa guida è quindi da intendersi come strumento utile per gli utenti di Hodlie, al fine di rendere la loro esperienza il più semplice possibile. '
  },
  subtitle: {
    'en-EN':
      'For any questions or more detailed information, please visit the Binance website or contact our support at <a href="mailto:support@hodlie.net">support@hodlie.net</a>',
    'it-IT':
      'Per qualsiasi dubbio o per informazioni più dettagliate, si prega di visitare il sito di Binance o di contattare il nostro supporto all’indirizzo <a href="mailto:support@hodlie.net">support@hodlie.net</a>'
  },
  lastUpdate: {
    'it-IT': 'Ultimo aggiornamento: ',
    'en-EN': 'Last update: '
  },
  sections: [
    {
      name: {
        'it-IT':
          'OPZIONE 1- Bonifico Bancario con Open Banking per depositare EURO',
        'en-EN': 'OPTION 1 - Bank Transfer with Open Banking to deposit EURO'
      },
      description: {
        'it-IT':
          'Il bonifico potrà essere effettuato solamente tramite Revolut o Fineco con una commissione di 0,50 euro fino a 499 euro + lo 0,12% dell’importo che si andrà a depositare. <br>Inizia <a href="https://www.binance.com/it/fiat/deposit/EUR" target=__blank>qui</a> il processo,  oppure segui <a href="https://www.binance.com/en/support/faq/how-to-deposit-eur-with-easy-bank-payment-adcfb5dc8ab14e06838ea8a4a05ca6d3" target=__blank>questa guida</a> utile a riguardo (solo in inglese per ora).',
        'en-EN':
          'The transfer can only be made through Revolut or Fineco with a fee of 0.50 euros up to 499 euros + 0.12% of the amount to be deposited. <br>Start <a href="https://www.binance.com/it/fiat/deposit/EUR" target=__blank>here</a> the process, or follow <a href="https://www.binance.com/en/support/faq/how-to-deposit-eur-with-easy-bank-payment-adcfb5dc8ab14e06838ea8a4a05ca6d3" target=__blank>this guide</a> useful in this regard (only in English for now).'
      },
      shortName: {
        'it-IT': 'n1',
        'en-EN': 'n1'
      }
    },
    {
      name: {
        'it-IT': 'OPZIONE 2 - Depositare EURO tramite Carta Bancaria (VISA)',
        'en-EN': 'OPTION 2 - Deposit EURO via Bank Card (VISA)'
      },
      description: {
        'it-IT':
          'Inizia <a href="https://www.binance.com/it/fiat/deposit/EUR" target=__blank>qui</a> il processo,  oppure segui <a href="https://www.binance.com/it/support/faq/come-depositare-fiat-con-carta-di-credito-debito-a6f4eb39c27347109b5091dfee3ec96a" target=__blank>questa guida</a> utile a riguardo.',
        'en-EN':
          'Start <a href="https://www.binance.com/it/fiat/deposit/EUR" target=__blank>here</a> the process, or follow <a href="https://www.binance.com/it/support/faq/come-depositare-fiat-con-carta-di-credito-debito-a6f4eb39c27347109b5091dfee3ec96a" target=__blank>this guide</a> useful in this regard.'
      },
      shortName: {
        'it-IT': 'n2',
        'en-EN': 'n2'
      }
    },
    {
      name: {
        'it-IT': `OPZIONE 3 - Bonifico SEPA per acquistare USDT`,
        'en-EN': `OPTION 3 - SEPA Transfer to buy USDT`
      },
      description: {
        'it-IT':
          'È possibile effettuarlo tramite qualsiasi banca, per comprare direttamente USDT a un costo di 1 euro di commissione. <br>Inizia da <a href="https://www.binance.com/it/fiat/deposit/EUR" target=__blank>qui</a>, selezionando come metodo di deposito BANK TRANSFER (SEPA)',
        'en-EN':
          'It is possible to do it through any bank, to buy USDT directly at a cost of 1 euro commission. <br>Start from <a href="https://www.binance.com/it/fiat/deposit/EUR" target=__blank>here</a>, selecting BANK TRANSFER (SEPA) as the deposit method.'
      },
      shortName: {
        'it-IT': 'n3',
        'en-EN': 'n3'
      }
    }
  ]
};
