import { useEffect } from 'react';
import PropTypes from 'prop-types';
import { breakpoints } from 'helpers/utils';

const token = 'ff6053057a1e22ffa9e0cbe81dcabdf5';
// const token = 'b6b9a6604a24e1c7b7bedaeb2b792725'; // Fabes test token

const convertData = userInfos => {
  let startDate = new Date(userInfos.created_at * 1000);
  let signupDate = `${startDate.getFullYear()}-${
    startDate.getMonth() + 1
  }-${startDate.getDate()}`;
  let data = {
    username: userInfos?.username || '',
    signup_date: signupDate || '',
    email_confirmed: userInfos?.email_confirmed || false,
    email: userInfos?.email || '',
    connected_exchanges: userInfos?.connected_exchanges?.length || 0,
    kyc: userInfos?.kyc_status || 'todo',
    pro_level: userInfos?.pro_tier || 0,
    virtual_bot_started: userInfos?.virtual_bot_started || false,
    real_bot_started: userInfos?.real_bot_started || false,
    referral: userInfos?.my_referral || '',
    device_type:
      window.innerWidth < breakpoints['sm']
        ? 'mobile'
        : window.innerWidth > breakpoints['xl']
        ? 'desktop'
        : 'none'
  };
  return data;
};

const UsetifulScriptLoader = ({
  userInfos = {},
  data = {},
  languageCode = 'it'
}) => {
  useEffect(() => {
    const userSegmentationData = {
      userId: userInfos?.tracking_id || '',
      ...convertData(userInfos),
      ...data,
      language: languageCode
    };
    // Segment tad in head
    const userSegmentationScript = document.createElement('script');
    userSegmentationScript.type = 'text/javascript';
    userSegmentationScript.innerHTML = `
      window.usetifulTags = ${JSON.stringify(userSegmentationData)};;
    `;
    document.head.appendChild(userSegmentationScript);

    // Usetiful script in head
    const usetifulScript = document.createElement('script');
    usetifulScript.async = true;
    usetifulScript.src = 'https://www.usetiful.com/dist/usetiful.js';
    usetifulScript.setAttribute('id', 'usetifulScript');
    usetifulScript.dataset.token = token;
    document.head.appendChild(usetifulScript);

    // Cleanup
    return () => {
      document.head.removeChild(userSegmentationScript);
      document.head.removeChild(usetifulScript);
    };
  }, [userInfos.tracking_id, languageCode]);

  return null; // No reder
};

UsetifulScriptLoader.propTypes = {
  userInfos: PropTypes.object,
  data: PropTypes.object,
  languageCode: PropTypes.string.isRequired
};

export default UsetifulScriptLoader;
