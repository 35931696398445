import React from 'react';
import PropTypes from 'prop-types';
import { loadStripe } from '@stripe/stripe-js';
import {
  EmbeddedCheckoutProvider,
  EmbeddedCheckout
} from '@stripe/react-stripe-js';
import { useCustomCheckout } from '@stripe/react-stripe-js';
import { CustomCheckoutProvider } from '@stripe/react-stripe-js';
import { publicKey } from './common';

const stripePromise = loadStripe(publicKey);
const stripe = loadStripe(publicKey, {
  betas: ['custom_checkout_beta_2']
});

const StripeEmbedded = ({ checkoutSession = '', ...rest }) => {
  return (
    <div id="checkout" {...rest}>
      <EmbeddedCheckoutProvider
        stripe={stripePromise}
        options={{
          clientSecret: checkoutSession
        }}
      >
        <EmbeddedCheckout />
      </EmbeddedCheckoutProvider>
    </div>
  );
};

StripeEmbedded.propTypes = {
  checkoutSession: PropTypes.string.isRequired
};

const CheckoutForm = () => {
  const checkout = useCustomCheckout();
  return <pre>{JSON.stringify(checkout.lineItems, null, 2)}</pre>;
};

const CustomCheckout = ({ checkoutSession }) => (
  <CustomCheckoutProvider
    stripe={stripe}
    options={{ clientSecret: checkoutSession }}
  >
    <CheckoutForm />
  </CustomCheckoutProvider>
);

CustomCheckout.propTypes = {
  checkoutSession: PropTypes.string.isRequired
};

export { StripeEmbedded, CustomCheckout };
