import { currencyMap } from 'services/coins/common';

/**
 * Converts a map of coin prices and their 24h percentage changes into another currency.
 * @param {Object} coinMap - The map containing price information of various coins including 24h percentage changes.
 * @param {String} currencySymbol - The target currency symbol (e.g., '$', '€').
 * @returns {Object} A new map of coin prices converted into the target currency and their 24h percentages.
 */
export const convertPrices = (coinMap, currencySymbol) => {
  // Determine the target currency reference in the coin map
  const targetCurrencyKey = currencyMap[currencySymbol];
  if (!targetCurrencyKey || !coinMap[targetCurrencyKey]) {
    throw new Error('Invalid or unsupported currency symbol.');
  }

  // Determine the conversion rate assuming it is always compared to USDT prices
  const baseCurrency = currencyMap['$'];
  const conversionRate =
    targetCurrencyKey === baseCurrency
      ? 1
      : coinMap[targetCurrencyKey][baseCurrency];

  const convertedPrices = {};
  const priceChangePercentages = {};

  // Convert prices from base currency to target currency
  const basePrices = coinMap[baseCurrency];
  for (let key in basePrices) {
    const coinDetails = basePrices[key];
    convertedPrices[key] = Number(coinDetails.price * conversionRate);
    priceChangePercentages[key] = coinDetails['24h_perc'];
  }

  // Ensure that the target currency key exists in converted prices with a default value of 1 if not present
  if (
    !Object.prototype.hasOwnProperty.call(convertedPrices, targetCurrencyKey)
  ) {
    convertedPrices[targetCurrencyKey] = 1;
  }

  return {
    prices: convertedPrices,
    perc_24h: priceChangePercentages
  };
};

/**
 * Calculates the total value of specified cryptocurrencies based on their amounts
 * and their current market prices.
 * @param {Object} coins - An object containing cryptocurrency symbols as keys and the amounts held as values.
 *                         The object format should be like { 'BTC': 2, 'ETH': 5 }.
 * @param {Object} priceMap - An object mapping each cryptocurrency symbol to its current market price.
 * @returns {Number} The total computed value of all provided cryptocurrency holdings.
 */
export const calculateCoinsValue = (coins, priceMap) => {
  let totalValue = 0;

  // Iterate over each coin entry in the coins object
  Object.entries(coins).forEach(([coinCode, coinAmount]) => {
    if (Object.keys(priceMap).length) {
      if (priceMap[coinCode] != null) {
        // Calculate value by multiplying the amount by the price
        totalValue += Number(coinAmount) * priceMap[coinCode];
      } else {
        // Log an error if no price is found for a coin
        console.error(
          `Price for ${coinCode} is not available in the price map.`
        );
      }
    }
  });

  // Round the total value to two decimal places to ensure it represents a valid currency amount
  return Number(totalValue.toFixed(2));
};

/**
 * Formats the amount of a given coin to the correct decimal precision as defined in coinsData,
 * without adding unnecessary decimals.
 * @param {String} coinName - The coin symbol (e.g., 'BTC', 'ETH') which needs to be formatted.
 * @param {Number} amount - The raw amount to be formatted.
 * @param {Object} coinsData - A map with coin codes as keys and their metadata including decimal precision.
 * @returns {Number} The amount formatted to the correct decimal precision for that coin.
 */
export const formatCoinAmount = (coinName, amount, coinsData) => {
  const coinInfo = coinsData[coinName];
  if (!coinInfo) {
    console.error(`No data available for the coin: ${coinName}`);
    return null;
  }

  const { decimalPrecision } = coinInfo;
  return parseFloat(amount.toFixed(decimalPrecision));
};

/**
 * Formats a number according to the given locale and precision.
 * @param {Number} number - The number to format.
 * @param {Number} decimalPrecision - Maximum number of decimal places to include.
 * @param {String} locale - Locale code to format the number (e.g., 'en-US').
 * @returns {String} A locale-aware formatted string representing the number.
 */
export const formatNumberForLocale = (
  number,
  decimalPrecision,
  locale = 'en-US'
) => {
  return number.toLocaleString(locale, {
    maximumFractionDigits: decimalPrecision,
    minimumFractionDigits: Math.min(
      decimalPrecision,
      (number.toString().split('.')[1] || '').length
    )
  });
};
