import { db } from 'services/cache/db';

export const getFromCache = async (key, cacheTime = 10) => {
  if (!db) return;
  const transaction = db.transaction(['cache']);
  const objectStore = transaction.objectStore('cache');
  const request = objectStore.get(key);

  return new Promise((resolve, reject) => {
    request.onsuccess = function (event) {
      const cachedResponse = event.target.result;
      if (cachedResponse) {
        const currentTime = Date.now();
        const elapsedTime = currentTime - cachedResponse.timestamp;

        if (elapsedTime < cacheTime * 60 * 1000) {
          resolve({
            cacheDataDB: cachedResponse.data,
            tsDB: cachedResponse.timestamp
          });
        } else {
          resolve({
            cacheDataDB: null,
            tsDB: null
          });
        }
      } else {
        resolve({
          cacheDataDB: null,
          tsDB: null
        });
      }
    };

    request.onerror = function () {
      reject('Error retrieving data from cache');
    };
  });
};

export const saveToCache = async (key, ts = Date.now(), data) => {
  if (!db) return;
  const transaction = db.transaction(['cache'], 'readwrite');
  const objectStore = transaction.objectStore('cache');
  const request = objectStore.put({
    key: key,
    data: data,
    timestamp: ts
  });

  return new Promise((resolve, reject) => {
    request.onsuccess = function () {
      resolve();
    };

    request.onerror = function () {
      reject('Error saving data to cache');
    };
  });
};

export const cleanCache = async (maxCacheTime = 30) => {
  if (!db) return;
  const transaction = db.transaction(['cache'], 'readwrite');
  const objectStore = transaction.objectStore('cache');
  const request = objectStore.openCursor();

  return new Promise((resolve, reject) => {
    request.onsuccess = function (event) {
      const cursor = event.target.result;
      if (cursor) {
        const currentTime = Date.now();
        const elapsedTime = currentTime - cursor.value.timestamp;
        if (elapsedTime >= maxCacheTime * 60 * 1000) {
          objectStore.delete(cursor.key);
        }
        cursor.continue();
      } else {
        resolve();
      }
    };

    request.onerror = function () {
      reject('Error cleaning the cache');
    };
  });
};
