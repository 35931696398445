export const supportLinks = {
  USER: 'https://meetings-eu1.hubspot.com/michela-valsecchi/user-onboarding',
  EXPLORER: 'https://meetings-eu1.hubspot.com/michela-valsecchi/explorer',
  'INVESTOR_&_PRIVATE':
    'https://meetings-eu1.hubspot.com/michela-valsecchi/private-investor'
};

export const supportDirectContact = {
  WA_LINK: 'https://wa.me/message/3RXHO7KL77TFC1',
  WA_MOBILE: '+39 378 066 5970'
};
