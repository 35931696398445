import React, { useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import { trackEvent } from '../mixpanel';
import { Spinner } from 'react-bootstrap';
import AppContext from 'context/Context';
import { endpointInterface } from 'services/endpointInterface/endpointInterface';
import Flex from 'components/common/Flex';
import { TraderProcedure as textLang } from 'staticData/languages';
import { integrationKey, scriptLink } from './common';

export const Docusign = ({
  url,
  setEndSession,
  setError,
  newStep,
  setStep,
  documentReady,
  setDocumentReady
}) => {
  const {
    config: { lang }
  } = useContext(AppContext);

  const documentSigned = async () => {
    const resp = await endpointInterface(
      lang,
      'backend',
      'docusignCompleted',
      'post',
      true
    );
    if (resp.responseStatus !== 200) {
      console.error('Error occurred while inserting document');
      return;
    }
    setTimeout(() => {
      setStep(newStep);
    }, 5000);
  };

  const handleEndSession = event => {
    setEndSession(true);
    if (event.sessionEndType === 'signing_complete') {
      trackEvent('docusign', {
        type: 'embedded'
      });
      // send data to backend
      documentSigned();
    } else setError(true);
  };

  useEffect(() => {
    if (!url) return;

    // Dinamically load the DocuSign script
    const loadDocusignScript = () => {
      return new Promise((resolve, reject) => {
        const script = document.createElement('script');
        script.src = scriptLink;
        script.async = true;

        script.onload = () => {
          resolve();
        };

        script.onerror = () => {
          reject(new Error('Error loading the DocuSign script'));
        };

        document.body.appendChild(script);
      });
    };

    loadDocusignScript()
      .then(() => {
        return window.DocuSign.loadDocuSign(integrationKey);
      })
      .then(docusign => {
        const signing = docusign.signing({
          url: url,
          displayFormat: 'default',
          style: {
            branding: {
              primaryButton: {
                backgroundColor: '#333',
                color: '#fff'
              }
            },
            signingNavigationButton: {
              finishText: 'You have finished the document! Hooray!',
              position: 'bottom-center'
            }
          }
        });

        signing.on('ready', () => {
          if (!documentReady) {
            setDocumentReady(true);
          }
        });

        signing.on('sessionEnd', event => {
          handleEndSession(event);
        });

        signing.mount('#agreement');
      })
      .catch(ex => {
        console.error(ex);
      });

    // Cleanup del script e timer
    return () => {
      const script = document.querySelector(`script[src="${scriptLink}"]`);
      if (script) {
        document.body.removeChild(script);
      }
    };
  }, [url]);

  return (
    <>
      <div
        className="docusign-agreement"
        id="agreement"
        style={{
          width: '100%',
          height: documentReady ? '800px' : '0',
          position: 'relative',
          overflow: 'hidden'
        }}
      ></div>
      {!documentReady && (
        <Flex
          className="my-2 justify-content-center align-items-center"
          style={{
            height: '400px'
          }}
        >
          <div>
            <p>{textLang.waitingDocument[lang]}</p>
            <Spinner />
          </div>
        </Flex>
      )}
    </>
  );
};

Docusign.propTypes = {
  url: PropTypes.string,
  setEndSession: PropTypes.func,
  setError: PropTypes.func,
  newStep: PropTypes.number.isRequired,
  setStep: PropTypes.func.isRequired,
  documentReady: PropTypes.bool.isRequired,
  setDocumentReady: PropTypes.func.isRequired
};

// Not used
// export const DocusignIframe = ({ url }) => {
//   if (!url) {
//     return null;
//   }

//   return (
//     <iframe
//       src={url}
//       width="100%"
//       height="800px"
//       allow="geolocation"
//       title="Docusign Agreement"
//       style={{
//         position: 'relative',
//         overflow: 'hidden'
//       }}
//     ></iframe>
//   );
// };
