import { botListInfo } from 'staticData/common';
import { botMedia } from './common';

export const getBotAnimation = async botType => {
  if (Object.keys(botMedia).includes(botType)) {
    const animModule = await botMedia[botType].anim();
    return animModule.default || animModule;
  } else {
    const generalAnimModule = await botMedia['general'].anim();
    return generalAnimModule.default || generalAnimModule;
  }
};

export const getBotImg = botType => {
  if (botListInfo.includes(botType)) return botMedia[botType].img;
  else return botMedia['general'].img;
};

export const preloadBotAnimations = () => {
  // Preload animations
  Object.values(botMedia).forEach(async media => {
    try {
      if (media.anim) await media.anim();
    } catch (error) {
      console.error(`Error preloading animation ${media.img}:`, error);
    }
  });
};
