import {
  name,
  headquarter,
  fiscalCode,
  email,
  site,
  privacyPolicyLink
} from 'staticData/hodlieInfos';

export const TermsOfUse = {
  title: {
    'en-EN': 'Terms Of Use',
    'it-IT': 'Condizioni Generali di Utilizzo del Sito'
  },
  onThisPage: {
    'en-EN': 'On this page',
    'it-IT': 'In Questa Pagina'
  },
  lastUpdate: {
    'it-IT': 'Ultimo aggiornamento: ',
    'en-EN': 'Last update: '
  },
  sections: [
    {
      link: 'application',
      name: {
        'it-IT': 'Ambito di applicazione',
        'en-EN': 'Field of application'
      },
      texts: [
        {
          'it-IT':
            'Le presenti Condizioni Generali di Utilizzo del Sito (di seguito, i "<b>Condizioni Generali di utilizzo</b>") disciplinano l’utilizzo da parte dell’utente quale utente registrato o semplice visitatore (di seguito, "<b>Utente</b>") del sito internet <a href=' +
            'https://' +
            site +
            ' target="_blank" rel="noreferrer">' +
            site +
            '</u></a> (di seguito, il "<b>Sito</b>") gestito da <b>' +
            name +
            '</b>, con sede legale in ' +
            headquarter +
            ', iscritta al Registro delle Imprese di Genova con il n. ' +
            fiscalCode +
            ', P.IVA n. ' +
            fiscalCode +
            ', e-mail <a href="mailto:' +
            email +
            '">' +
            email +
            '</a>, (di seguito, "<b>Hodlie</b>").',
          'en-EN':
            'The following terms of use (hereinafter, the "<b>Terms Of Use</b>") govern the use by the user as a registered user or mere visitor (hereinafter, the "<b>User</b>") of the website <a href=' +
            'https://' +
            site +
            ' target="_blank" rel="noreferrer">' +
            site +
            '</u></a> (hereinafter, the "<b>Site</b>") managed by ' +
            name +
            '</b>, with registered office in ' +
            headquarter +
            ', registered with the Genoa Chamber of Commerce under number ' +
            fiscalCode +
            ', VAT number ' +
            fiscalCode +
            ', e-mail address <a href="mailto:' +
            email +
            '">' +
            email +
            '</a>, (hereinafter, "<b>Hodlie</b>").'
        },
        {
          'it-IT':
            "Il Sito ha finalità informative e di commercio elettronico. Si prega di leggere attentamente le presenti Condizioni Generali di Utilizzo – così come le altre sezioni del Sito quali l’<a href='" +
            privacyPolicyLink +
            "'  target='_blank' rel='noreferrer'><b><u>Informativa sul trattamento dei dati personali</u></b></a>, la Cookie Policy, i <a href='/termsandconditions'><b><u>Termini e Condizioni del Servizio</u></b></a> – prima di utilizzare il Sito e di stamparne una copia per futuro riferimento.",
          'en-EN':
            "The Site is for informational and e-commerce purposes. Please read these Terms of Use carefully-as well as other sections of the Site such as the <a href='" +
            privacyPolicyLink +
            "'  target='_blank' rel='noreferrer'><b><u>Privacy Policy</u></b></a>, <b>Cookie Policy</b>, <a href='/termsandconditions'><b><u>Terms & Conditions</u></b></a> - before using the Site and print a copy for future reference."
        },
        {
          'it-IT':
            "Effettuando l'accesso o utilizzando i servizi presenti sul Sito, l'Utente accetta le presenti Condizioni Generali di Utilizzo e si impegna a rispettarle. Se l'Utente non intende accettare le presenti Condizioni Generali di Utilizzo, è pregato di astenersi dall'utilizzo del Sito.",
          'en-EN':
            'By accessing or using the services on the Site, the User accepts these Terms of Use and agrees to abide by them. If the User does not intend to accept these Terms of Use, please refrain from using the Site.'
        }
      ]
    },
    {
      link: 'changes',
      name: {
        'it-IT': 'Modifiche alle Condizioni Generali di Utilizzo',
        'en-EN': 'Changes to Terms Of Use'
      },
      texts: [
        {
          'it-IT':
            "Hodlie si riserva il diritto di modificare le presenti Condizioni Generali di Utilizzo per motivi di legge, modifica dei servizi offerti o esigenze aziendali. Eventuali modifiche delle Condizioni Generali di Utilizzo saranno notificate in anticipo. La versione aggiornata delle Condizioni Generali di Utilizzo è pubblicata di volta in volta sul Sito e le Condizioni Generali di Utilizzo in vigore sono quelle disponibili sul Sito. Qualora l’Utente non intendesse accettare la nuova versione delle Condizioni Generali di Utilizzo dovrà chiudere il proprio account sul Sito a norma dell’articolo 4.5. che segue, nonché cessare l'utilizzo del Sito e dei servizi offerti tramite lo stesso.",
          'en-EN':
            'Hodlie reserves the right to change these General Terms of Use for reasons of law, change of services offered, or business needs. Any changes to the General Terms of Use will be notified in advance. The updated version of the General Terms of Use is posted on the Site from time to time, and the General Terms of Use in effect are those available on the Site. If the User does not intend to accept the new version of the General Terms of Use, he shall close his account on the Site in accordance with Article 4.5. below, as well as cease using the Site and the services offered through it.'
        }
      ]
    },
    {
      link: 'obligations',
      name: {
        'it-IT': "Obblighi dell'Utente",
        'en-EN': 'Obligations of the User'
      },
      texts: [
        {
          'it-IT':
            "L’Utente dovrà conformarsi con i termini delle Condizioni Generali di Utilizzo del Sito, dei <a href='/termsandconditions'><b><u>Termini e Condizioni del Servizio</u></b></a>, nonché con la normativa per tempo applicabile nell'utilizzo del Sito e dei servizi offerti tramite lo stesso e non dovrà violare i diritti di Hodlie e degli altri soggetti che operano sul Sito, inclusi eventuali fornitori dei servizi presenti sul Sito.",
          'en-EN':
            'You shall comply with the terms of the Terms of Use of the Site, the <a href="/termsandconditions"><b><u>Terms & Conditions</u></b></a>, and the law for time applicable in your use of the Site and the services offered through the same, and shall not violate the rights of Hodlie and other entities operating on the Site, including any providers of the services on the Site.'
        }
      ]
    },
    {
      link: 'login',
      name: {
        'it-IT': 'Accesso, registrazione e utilizzo del Sito',
        'en-EN': 'Login, registration, and use of the Site'
      },
      texts: [
        {
          'it-IT':
            "L'Utente può consultare e registrarsi gratuitamente al Sito, fermo restando che è a carico dell'Utente il costo del collegamento alla rete Internet utilizzato per accedere al Sito, secondo le tariffe, i termini e le condizioni praticate dal proprio operatore.",
          'en-EN':
            'The User can browse and register for free on the Website, while the User is responsible for the cost of the Internet connection used to access the Website, according to the rates, terms, and conditions applied by their own operator.'
        },
        {
          'it-IT':
            'La registrazione al Sito comporterà la creazione di un account personale, non trasferibile e condivisibile tramite il quale l’Utente potrà, tra gli altri, revisionare gli ordini effettuati tramite il Sito a norma dai <a href="/termsandconditions"><b><u>Termini e Condizioni del Servizio</u></b></a>.',
          'en-EN':
            'Registration on the Website will involve the creation of a personal account, non-transferable and shareable, through which the User can, among other things, review orders placed through the Website in accordance with the <a href="/termsandconditions"><b><u>Terms & Conditions</u></b></a>.'
        },
        {
          'it-IT':
            "L’account personale è accessibile tramite credenziali di accesso che comprendono username e password, in conformità ai criteri per la creazione della password di volta in volta indicati sul Sito. La username dell'Utente, invece, coinciderà con l'indirizzo di posta elettronica indicato dall'Utente stesso in fase di registrazione al Sito. L’Utente è tenuto a mantenere riservate le credenziali di accesso.",
          'en-EN':
            "The personal account is accessible through login credentials that include username and password, following the password creation criteria as indicated on the Website. The User's username will correspond to the email address provided by the User during registration on the Website. The User is responsible for keeping the login credentials confidential."
        },
        {
          'it-IT':
            "A seguito della registrazione sul Sito, l'Utente riceverà un messaggio di posta elettronica di conferma dell'avvenuta registrazione e creazione del proprio account personale all'indirizzo e-mail indicato in fase di registrazione.",
          'en-EN':
            'After registering on the Website, the User will receive a confirmation email of the successful registration and creation of their personal account at the email address provided during registration.'
        },
        {
          'it-IT':
            "L'Utente può cancellare in qualsiasi momento il proprio account inviando un'e-mail a Hodlie agli indirizzi sopra indicati all’articolo 1.1. Alla ricezione della richiesta di cancellazione dell’account personale da parte dell’Utente, ogni rapporto tra l'Utente e Hodlie si intenderà risolto e lo username e la password dell'Utente verranno cancellati. Eventuali abbonamenti sottoscritti prima della richiesta di cancellazione, saranno comunque gestiti secondo quanto indicato nei <a href='/termsandconditions'><b><u>Termini e Condizioni del Servizio</u></b></a>.",
          'en-EN':
            "The User can delete their account at any time by sending an email to Hodlie at the addresses provided in Article 1.1. Upon receiving the User's request for cancellation of the personal account, the relationship between the User and Hodlie will be considered terminated, and the User's username and password will be deleted. Any subscriptions subscribed prior to the deletion request will still be managed according to the <a href='/termsandconditions'><b><u>Terms & Conditions</u></b></a>."
        },
        {
          'it-IT':
            "La registrazione al Sito permette all’Utente di abbonarsi alla piattaforma di Hodlie e utilizzare i relativi trading bot secondo quanto previsto dai <a href='/termsandconditions'><b><u>Termini e Condizioni del Servizio</u></b></a>. Hodlie potrà aggiungere ulteriori funzionalità all'account che saranno notificate tramite il Sito e/o via e-mail all’Utente.",
          'en-EN':
            "Registering on the Website allows the User to subscribe to Hodlie's platform and use the associated trading bots as outlined in the <a href='/termsandconditions'><b><u>Terms & Conditions</u></b></a>. Hodlie may add additional features to the account, which will be notified through the Website and/or via email to the User."
        },
        {
          'it-IT':
            "Al momento della compilazione del modulo di registrazione al Sito, l’Utente è tenuto a comunicare dati veritieri, esatti, attuali e completi e a comunicare immediatamente a Hodlie ogni cambiamento delle informazioni fornite in precedenza. Hodlie si riserva il diritto di verificare in qualsiasi momento e con qualsiasi mezzo a sua disposizione le informazioni fornite, anche richiedendo all'Utente idonea documentazione di supporto e, in caso di violazione del presente articolo 4.7., di disattivare o sospendere l'account dell'Utente. L’Utente potrà, in ogni caso, accedere e aggiornare tempestivamente i dati tramite la sezione \"Il mio account\" del Sito, ove necessario, in modo che sia sempre assicurata la loro esattezza, attualità e completezza.",
          'en-EN':
            'When filling out the registration form on the Website, the User is required to provide truthful, accurate, current, and complete information, and to promptly notify Hodlie of any changes to the previously provided information. Hodlie reserves the right to verify the provided information at any time and through any means available, including requesting suitable supporting documentation from the User. In case of violation of this Article 4.7., Hodlie may deactivate or suspend the User\'s account. The User can always access and promptly update the data through the "My Account" section of the Website, as necessary, to ensure their accuracy, currency, and completeness.'
        },
        {
          'it-IT':
            "L’Utente è responsabile di tutte le attività che verranno effettuate tramite il suo account. A tal fine, l'Utente si impegna ad adottare le precauzioni adeguate per garantire che la sua password rimanga sicura e riservata e si impegna a informare Hodlie immediatamente nel caso abbia motivo di credere che qualunque soggetto terzo sia a conoscenza della sua password, nel caso in cui la sua password sia, o presumibilmente stia per essere, utilizzata in modo non autorizzato, nonché in caso di sua perdita o sottrazione.",
          'en-EN':
            'The User is responsible for all activities conducted through their account. For this purpose, the User agrees to take appropriate precautions to ensure the security and confidentiality of their password, and agrees to promptly inform Hodlie in case they have reason to believe that any third party is aware of their password, if their password is, or is likely to be, used in an unauthorized manner, or in the event of its loss or theft.'
        },
        {
          'it-IT':
            'L’Utente non potrà utilizzare il proprio account e il Sito:',
          'en-EN': 'The User may not use their own account and the Website:',
          subtexts: [
            {
              'it-IT':
                'in modo tale da causare, o da poter causare, interruzioni, danni o malfunzionamenti agli stessi e alle loro funzionalità; e',
              'en-EN':
                'in a way that causes, or could cause, interruptions, damages, or malfunctions to them and their functionalities; and'
            },
            {
              'it-IT':
                'per finalità fraudolente, o comunque per commettere attività illecite.',
              'en-EN':
                'for fraudulent purposes, or in any case, to engage in unlawful activities.'
            }
          ]
        },
        {
          'it-IT':
            "In ogni caso l'Utente ha l’obbligo di non interferire con il funzionamento del Sito e, in particolare, si impegna a non tentare di eluderne la sicurezza, a non manometterlo e comunque a non pregiudicare in alcun modo l’operatività del Sito o di qualunque sistema informatico, server, router o altro dispositivo informatico di Hodlie.",
          'en-EN':
            'In any case, the User is obligated not to interfere with the operation of the Website, and in particular, undertakes not to attempt to circumvent its security, not to tamper with it, and in any case, not to impair the operation of the Website or any computer system, server, router, or other computing device of Hodlie.'
        }
      ]
    },
    {
      link: 'license',
      name: {
        'it-IT': "Licenza per l'accesso ai servizi",
        'en-EN': 'License for access to services'
      },
      texts: [
        {
          'it-IT':
            "A condizione che l’Utente rispetti le presenti Condizioni Generali di Utilizzo del Sito, Hodlie concede una licenza limitata, non esclusiva, non trasferibile e non sub-licenziabile per accedere al Sito unicamente per la fruizione dei servizi offerti tramite lo stesso. Questa licenza non include alcun diritto di rivendita o uso commerciale di alcuna funzionalità o contenuto del Sito, né il diritto di effettuare qualsiasi tipo di download o copia di informazioni dell'account sul Sito. Tutti i diritti non espressamente conferiti dalle presenti Condizioni Generali di Utilizzo del Sito restano di proprietà di Hodlie e/o degli altri soggetti autorizzati ad operare sul Sito.",
          'en-EN':
            'Provided that the User complies with these Terms of Use of the Website, Hodlie grants a limited, non-exclusive, non-transferable, and non-sub licensable license to access the Website solely for the use of the services offered through it. This license does not include any right to resell or use for commercial purposes any feature or content of the Website, nor the right to download or copy any account information from the Website. All rights not expressly granted by these Terms of Use of the Website remain the property of Hodlie and/or other authorized entities operating on the Website.'
        },
        {
          'it-IT':
            "Ad eccezione di quanto previsto all’articolo 6.2 che segue, non è possibile riprodurre, duplicare, copiare, vendere, rivendere o in ogni altro modo utilizzare per qualsiasi uso commerciale i contenuti e segni distintivi di Hodlie e ogni altro contenuto o funzionalità del Sito, in tutto o in parte senza l'espresso consenso scritto di Hodlie. Non è possibile, inoltre, procedere al framing del Sito o utilizzare tecniche che consentano di appropriarsi indebitamente di qualsiasi marchio, logo o altra informazione o contenuto del Sito (inclusi immagini, testo, impostazioni di pagina, o formato).",
          'en-EN':
            'Except as provided in the following Article 6.2, it is not possible to reproduce, duplicate, copy, sell, resell, or otherwise use for any commercial purpose the content and trademarks of Hodlie and any other content or functionality of the Website, in whole or in part, without the express written consent of Hodlie. It is also not permitted to frame the Website or use techniques that unduly appropriate any trademark, logo, or other information or content from the Website (including images, text, page settings, or format).'
        }
      ]
    },
    {
      link: 'property',
      name: {
        'it-IT': 'Diritti di proprietà industriale ed intellettuale',
        'en-EN': 'Industrial and intellectual property rights'
      },
      texts: [
        {
          'it-IT':
            "Se non diversamente specificato, tutti i contenuti e i materiali presenti o resi disponibili sul Sito sotto forma, tra gli altri, di testi, cataloghi, fotografie, illustrazioni, immagini, grafici, disegni tecnici, suoni, animazioni, pulsanti, icone, video, testi pubblicitari, marchi, nomi di dominio, ivi compresa la disposizione degli stessi, sono di proprietà di Hodlie e/o dei propri fornitori di contenuti e sono protetti dalle leggi applicabili sul diritto d'autore, sul diritto sulle banche dati e sugli altri diritti di proprietà intellettuale.",
          'en-EN':
            'Unless otherwise specified, all content and materials present or made available on the Website, including but not limited to texts, catalogs, photographs, illustrations, images, graphics, technical drawings, sounds, animations, buttons, icons, videos, advertising texts, trademarks, domain names, including their arrangement, are the property of Hodlie and/or its content providers and are protected by applicable copyright laws, database rights, and other intellectual property rights.'
        },
        {
          'it-IT':
            "L'Utente è autorizzato a scaricare, visualizzare o stampare contenuti del Sito per scopi esclusivamente personali e di natura non commerciale, con modalità tali da non arrecare alcun pregiudizio ai diritti di proprietà industriale ed intellettuale di Hodlie o di eventuali terzi. I contenuti del Sito non potranno in nessun caso essere utilizzati per scopi diversi, ivi compresi, a titolo esemplificativo e non esaustivo, la loro distribuzione, modifica, riproduzione, trasmissione o diffusione, senza il preventivo consenso scritto da parte di Hodlie. Se l'Utente utilizza il Sito e/o i contenuti in esso pubblicati in violazione delle presenti Condizioni Generali di Utilizzo, l'Utente potrà essere invitato da Hodlie a interrompere l'utilizzo del Sito e a distruggere o restituire i contenuti, ferma restando la facoltà di Hodlie di assumere ogni ulteriore iniziativa per la tutela dei diritti lesi e il risarcimento dei danni subiti.",
          'en-EN':
            "The User is authorized to download, view, or print content from the Website for purely personal and non-commercial purposes, in a manner that does not prejudice the industrial and intellectual property rights of Hodlie or any third parties. The contents of the Website may not, in any case, be used for different purposes, including, for example and not exhaustively, their distribution, modification, reproduction, transmission, or dissemination, without the prior written consent of Hodlie. If the User uses the Website and/or its contents in violation of these Terms of Use, Hodlie may invite the User to cease using the Website and to destroy or return the contents, without prejudice to Hodlie's right to take further action to protect the rights violated and seek compensation for damages suffered."
        }
      ]
    },
    {
      link: 'warranty',
      name: {
        'it-IT': 'Garanzia',
        'en-EN': 'Warranty'
      },
      texts: [
        {
          'it-IT':
            'Nei limiti di quanto consentito dalla legge applicabile e fatti salvi, in particolare, i casi di dolo e colpa grave e i limiti previsti dalla normativa a tutela dei consumatori, Hodlie non fornisce alcuna garanzia rispetto a quanto segue:',
          'en-EN':
            'To the extent permitted by applicable law and subject, in particular, to cases of willful misconduct and gross negligence and to the limits provided for in consumer protection legislation, Hodlie makes no warranty with respect to the following:',
          subtexts: [
            {
              'it-IT':
                'il fatto che il Sito o i suoi contenuti siano costantemente accessibili;',
              'en-EN':
                'the fact that the Website or its content are constantly accessible;'
            },
            {
              'it-IT':
                "il verificarsi di eventuali disservizi consistenti in ritardi o interruzioni nel funzionamento dei servizi di comunicazione elettronica che impediscano l'accesso o l'utilizzo del Sito; e",
              'en-EN':
                'the occurrence of any malfunctions consisting of delays or interruptions in the operation of electronic communication services that prevent access to or use of the Website; and'
            },
            {
              'it-IT':
                "l'assenza di virus, malware o altri elementi che rendono il Sito e/o i suoi contenuti pericolosi. L’Utente è responsabile per l'attuazione di procedure e controlli atti a soddisfare le esigenze di sicurezza e l'affidabilità dei dati in entrata e in uscita, quali ad esempio l'installazione di un applicativo antivirus.",
              'en-EN':
                'the absence of viruses, malware, or other elements that make the Website and/or its content dangerous. The User is responsible for implementing procedures and controls to meet the security needs and reliability of incoming and outgoing data, such as installing antivirus software.'
            }
          ]
        },
        {
          'it-IT':
            'Fermo restando quanto previsto dai <a href="/termsandconditions"><b><u>Termini e Condizioni del Servizio</u></b></a>, resta inteso che il Sito potrebbe presentare alcuni prodotti in accostamento ad immagini o riproduzioni grafiche la cui finalità è solamente illustrativa.',
          'en-EN':
            'Notwithstanding the provisions of the <a href="/termsandconditions"><b><u>Terms & Conditions</u></b></a>, it is understood that the Site may present some products in juxtaposition with images or graphic reproductions whose purpose is illustrative only.'
        }
      ]
    },
    {
      link: 'liability',
      name: {
        'it-IT': 'Limitazione di responsabilità',
        'en-EN': 'Limitation of liability'
      },
      texts: [
        {
          'it-IT':
            "Nei limiti previsti dalla legge e fatti salvi i casi di dolo e colpa grave, Hodlie declina qualsiasi responsabilità rispetto a qualsivoglia danno o pregiudizio subito in qualsiasi modo dall'Utente in conseguenza dell’accesso e utilizzo del Sito e/o download di qualsivoglia contenuto a qualsiasi titolo presente sul Sito.",
          'en-EN':
            'To the extent permitted by law and without prejudice to cases of willful misconduct and gross negligence, Hodlie disclaims any liability for any damage or harm suffered by the User in any way as a result of accessing and using the Website and/or downloading any content for any reason present on the Website.'
        },
        {
          'it-IT':
            'Hodlie non è responsabile per eventuali disservizi e costi derivanti dalla comunicazione di informazioni erronee o non aggiornate da parte dell’Utente o, comunque, dovuti alla condotta dell’Utente.',
          'en-EN':
            "Hodlie is not responsible for any malfunctions and costs resulting from the communication of incorrect or outdated information by the User or, in any case, due to the User's conduct."
        },
        {
          'it-IT':
            "Hodlie non assume alcuna responsabilità per qualsiasi perdita o danno derivante dal fatto che l'Utente non abbia conservato in maniera sicura la propria password o non abbia comunicato a Hodlie l'utilizzo non autorizzato, la perdita o la sottrazione della password a norma dell'articolo 4.8. sopra.",
          'en-EN':
            'Hodlie assumes no responsibility for any loss or damage resulting from the fact that the User has not securely stored their password or has not notified Hodlie of the unauthorized use, loss, or theft of the password as per Article 4.8. above.'
        },
        {
          'it-IT':
            "Fatte salve le garanzie sulla fornitura e la conformità dei servizi resi sul Sito e previste dai <a href='/termsandconditions'><b><u>Termini e Condizioni del Servizio</u></b></a>, Hodlie si riserva il diritto di sospendere, eliminare, interrompere o modificare in tutto o parte il Sito senza preavviso. Hodlie non sarà responsabile nei confronti dell'Utente se per qualsiasi motivo il Sito non fosse disponibile, in tutto o in parte, né per l'eventuale modifica, sospensione, o interruzione definitiva del Sito.",
          'en-EN':
            "Without prejudice to the guarantees regarding the provision and compliance of the services provided on the Website as stipulated in the <a href='/termsandconditions'><b><u>Terms & Conditions</u></b></a>, Hodlie reserves the right to suspend, delete, interrupt, or modify the Website in whole or in part without notice. Hodlie will not be liable to the User if, for any reason, the Website is not available, in whole or in part, or for any possible modification, suspension, or permanent interruption of the Website."
        },
        {
          'it-IT':
            'Quanto precede deve naturalmente intendersi applicabile ove consentito nei limiti massimi di legge.',
          'en-EN':
            'The above must naturally be understood to be applicable to the maximum extent permitted by law.'
        }
      ]
    },
    {
      link: 'events',
      name: {
        'en-EN': 'Force majeure events',
        'it-IT': 'Eventi di forza maggiore'
      },
      texts: [
        {
          'it-IT':
            'Hodlie non sarà responsabile per i ritardi di accesso al Sito e alle sue funzionalità che siano dovuti a malfunzionamenti della rete Internet, a casi fortuiti, cause di forza maggiore, ad epidemie, a provvedimenti di legge o in ogni caso eventi al di fuori del controllo di Hodlie.',
          'en-EN':
            "Hodlie will not be responsible for delays in accessing the Website and its functionalities that are due to Internet network malfunctions, fortuitous events, force majeure, epidemics, legal provisions, or in any case events beyond Hodlie's control."
        }
      ]
    },
    {
      link: 'links',
      name: {
        'en-EN': 'Links to third-party sites',
        'it-IT': 'Collegamenti a siti di terzi'
      },
      texts: [
        {
          'it-IT':
            "Il Sito può contenere collegamenti ipertestuali (hyperlink) a siti web gestiti da terzi. In ogni caso, Hodlie non è responsabile dei contenuti di siti terzi resi accessibili tramite il Sito. L'inserimento da parte di Hodlie di hyperlink a tali siti web non implica alcuna accettazione da parte di Hodlie del materiale pubblicato su tali siti web o qualsiasi altra relazione tra Hodlie e i gestori degli stessi.",
          'en-EN':
            'The Website may contain hyperlinks to websites operated by third parties. In any case, Hodlie is not responsible for the content of third-party sites accessible through the Website. The inclusion by Hodlie of hyperlinks to such websites does not imply any endorsement by Hodlie of the material published on such websites or any other relationship between Hodlie and the operators of those websites.'
        }
      ]
    },
    {
      link: 'violations',
      name: {
        'en-EN': 'Violations, liability and indemnification',
        'it-IT': 'Violazioni, responsabilità e indennizzo'
      },
      texts: [
        {
          'it-IT':
            "L'Utente accetta di tenere indenne e manlevata Hodlie in relazione a qualsiasi responsabilità, danno e costo (incluse, senza alcuna limitazione, spese legali) in cui Hodlie possa incorrere a causa di: (i) una violazione da parte dell'Utente delle presenti Condizioni Generali di Utilizzo; (ii) pretese di terzi che siano fondate sull'uso del Sito e/o dei contenuti nello stesso previsti da parte dell'Utente; ovvero (iii) informazioni o contenuti che siano stati spediti, trasmessi o caricati dall'Utente attraverso il Sito o l'account personale.",
          'en-EN':
            "The User agrees to indemnify and hold Hodlie harmless for any liability, damage, and cost (including, without limitation, legal fees) that Hodlie may incur due to: (i) a breach of these Terms of Use by the User; (ii) third-party claims based on the User's use of the Website and/or the content provided therein; or (iii) information or content sent, transmitted, or uploaded by the User through the Website or the personal account."
        },
        {
          'it-IT':
            "In caso di violazione degli obblighi di cui alle presenti Condizioni Generali di Utilizzo da parte dell’Utente, Hodlie potrà impedire l'accesso al Sito e/o ai servizi offerti tramite il Sito e/o sospendere l'accesso all'account, fermo restando",
          'en-EN':
            'In case of breach of the obligations set forth in these Terms of Use by the User, Hodlie may prevent access to the Website and/or the services offered through the Website and/or suspend access to the account, without prejudice to',
          subtexts: [
            {
              'it-IT':
                'Il diritto di risoluzione delle Condizioni Generali di Utilizzo in caso di violazione degli articoli 3 (Obblighi dell’Utente), 5 (Licenza per l’accesso ai servizi) e 6 (Diritti di proprietà industriale ed intellettuale);',
              'en-EN':
                "The right to terminate the Terms of Use in case of violation of Articles 3 (User's Obligations), 5 (License for access to services), and 6 (Industrial and Intellectual Property Rights);"
            },
            {
              'it-IT':
                "L'obbligo da parte dell’Utente di risarcire il danno subito da Hodlie e dagli eventuali fornitori a causa della violazione.",
              'en-EN':
                'The obligation of the User to compensate Hodlie and any suppliers for the damage suffered due to the violation.'
            }
          ]
        }
      ]
    },
    {
      link: 'protection',
      name: {
        'en-EN': 'Protection of personal data',
        'it-IT': 'Protezione dei dati personali'
      },
      texts: [
        {
          'it-IT':
            "Qualsiasi informazione relativa a dati di natura personale inviata elettronicamente tramite il Sito dall'Utente sarà trattata in conformità con la normativa in materia di protezione dei dati personali applicabile – e, in particolare, del Decreto Legislativo n. 196/2003, come modificato dal Decreto Legislativo n. 101/2018 (Codice Privacy) e del Regolamento UE 2016/679 (Regolamento generale sulla protezione dei dati personali) – e quanto indicato nell'<a href='" +
            privacyPolicyLink +
            "'  target='_blank' rel='noreferrer'><b><u>Informativa sul trattamento dei dati personali</u></b></a> pubblicata sul Sito.",
          'en-EN':
            "Any information related to personal data sent electronically through the Website by the User will be processed in accordance with the applicable personal data protection regulations – in particular, Legislative Decree no. 196/2003, as amended by Legislative Decree no. 101/2018 (Privacy Code), and EU Regulation 2016/679 (General Data Protection Regulation) – and as indicated in the <a href='" +
            privacyPolicyLink +
            "'  target='_blank' rel='noreferrer'><b><u>Privacy Policy</u></b></a> published on the Website."
        }
      ]
    },
    {
      link: 'law',
      name: {
        'en-EN': 'Applicable law and place of jurisdiction',
        'it-IT': 'Legge applicabile e foro competente'
      },
      texts: [
        {
          'it-IT':
            'Il Sito, come attualmente configurato, è progettato per essere fruito da Utenti che si trovino in Italia e Hodlie non fornisce alcuna garanzia circa la circostanza che i suoi contenuti sono conformi con i requisiti di legge applicabili fuori dal predetto territorio.',
          'en-EN':
            'The Website, as currently configured, is designed to be used by Users located in Italy, and Hodlie does not provide any guarantee that its content complies with the legal requirements applicable outside the aforementioned territory.'
        },
        {
          'it-IT':
            "Le presenti Condizioni Generali di Utilizzo sono regolate dalla legge italiana e qualsiasi controversia relativa al Sito è affidata alla competenza esclusiva del tribunale di residenza o domicilio dell'Utente se ubicati nel territorio dello Stato italiano.",
          'en-EN':
            'These General Conditions of Use are governed by Italian law, and any dispute related to the Website is subject to the exclusive jurisdiction of the court of residence or domicile of the User if located within the territory of the Italian State.',
          subtexts: [
            {
              'it-IT':
                'Nel caso in cui l’Utente sia un consumatore, il tribunale di residenza del consumatore avrà competenza esclusiva in relazione a qualsivoglia controversia derivante o relativa alle presenti Condizioni Generali di Utilizzo.',
              'en-EN':
                "In the event that the User is a consumer, the court of the consumer's residence will have exclusive jurisdiction for any dispute arising from or related to these Terms of Use."
            },
            {
              'it-IT':
                "Nel caso in cui l’Utente sia un'impresa, il Tribunale di Milano avrà competenza esclusiva in relazione a qualsivoglia controversia derivante o relativa alle presenti Condizioni Generali di Utilizzo.",
              'en-EN':
                'In the event that the User is a business, the Court of Milan will have exclusive jurisdiction for any dispute arising from or related to these Terms of Use.'
            }
          ]
        },
        {
          'en-EN':
            'Without prejudice to the User’s right to take action before the competent court provided for in Clause 13.2. above, in accordance with Article 14 of Regulation 524/2013/EC, the User also has the option of having recourse to the platform established by the European Commission for online dispute resolution ("<b>ODR Platform</b>"), through the assistance of an impartial body. The ODR Platform can be accessed at the following <a href="https://ec.europa.eu/consumers/odr/main/?event=main.home.show" target="_blank" rel="noreferrer"><u>link</u></a>.',
          'it-IT':
            'Fatto salvo il diritto dell’Utente di agire davanti al tribunale competente previsto alla Clausola 13.2. sopra, in conformità con l’articolo 14 del Regolamento 524/2013/CE, l’Utente ha altresì la possibilità di ricorrere alla piattaforma istituita dalla Commissione Europea per la risoluzione delle controversie online ("<b>Piattaforma ODR</b>"), tramite l’assistenza di un organo imparziale. La Piattaforma ODR è accessibile al seguente <a href="https://ec.europa.eu/consumers/odr/main/?event=main.home.show" target="_blank" rel="noreferrer"><u>link</u></a>.'
        }
      ]
    }
  ],
  ending: {
    'en-EN':
      'Pursuant to and for the purposes of Articles 1341 and 1342 of the Civil Code, the User declares that he/she has understood and expressly accepts the following articles of the Terms of Use of the Site: 2 (Amendments to the General Terms of Use), 5 (License for Access to Services), 6 (Industrial and Intellectual Property Rights), 7 (Warranty), 8 (Limitation of Liability), 9 (Force Majeure Events) and 11 (Violations, Liability and Indemnification).',
    'it-IT':
      'Ai sensi e per gli effetti degli articoli 1341 e 1342 del codice civile, l’Utente dichiara di aver compreso e di accettare espressamente i seguenti articoli delle Condizioni Generali di Utilizzo del Sito: 2 (Modifiche delle Condizioni Generali di Utilizzo), 5 (Licenza per l’accesso ai servizi), 6 (Diritti di proprietà industriale ed intellettuale), 7 (Garanzia), 8 (Limitazione di responsabilità), 9 (Eventi di forza maggiore) e 11 (Violazioni, responsabilità e indennizzo).'
  }
};
