import {
  getFromCache as getFromCacheLevel1,
  saveToCache as saveToCacheLevel1,
  cleanCache as cleanCacheLevel1
} from 'services/cache/cache_1_level_Code';
import {
  getFromCache as getFromCacheLevel2,
  saveToCache as saveToCacheLevel2,
  cleanCache as cleanCacheLevel2
} from 'services/cache/cache_2_level_DB';

export const getFromCache = async (
  key,
  cacheTime1 = 10,
  cacheTime2 = cacheTime1
) => {
  if (process.env.REACT_APP_DISABLE_CACHE !== 'true') {
    let cacheData = getFromCacheLevel1(key, cacheTime1);
    if (cacheData !== null) return cacheData;

    let { cacheDataDB, tsDB } = await getFromCacheLevel2(key, cacheTime2);
    if (cacheDataDB !== null) {
      // Save the data to the first level cache
      saveToCacheLevel1(key, tsDB, cacheDataDB);
      return cacheDataDB;
    }
  }

  return null;
};

export const saveToCache = async (key, ts = Date.now(), data) => {
  if (process.env.REACT_APP_DISABLE_CACHE !== 'true') {
    saveToCacheLevel1(key, ts, data);
    await saveToCacheLevel2(key, ts, data);
  }
};

export const cleanCache = async (
  maxCacheTime1 = 30,
  maxCacheTime2 = maxCacheTime1
) => {
  cleanCacheLevel1(maxCacheTime1);
  await cleanCacheLevel2(maxCacheTime2);
};
