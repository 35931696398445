import React from 'react';
import Avatar, { AvatarGroup } from 'components/common/Avatar';
import { asQuoteCoins, asSideCoins } from './common';
import { coinsData } from 'services/coins/common';

// Function to sort the array with special items moved to the end
const customSort = (
  mainArray,
  quoteItems = asQuoteCoins,
  sideCoinsItem = asSideCoins
) => {
  // Filter the array to separate special and non-special items
  const nonSpecialItems = mainArray.filter(
    item => !quoteItems.includes(item) && !sideCoinsItem.includes(item)
  );
  const quoteOnlyItems = mainArray.filter(item => quoteItems.includes(item));
  const sideOnlyItems = mainArray.filter(item => sideCoinsItem.includes(item));
  // Sort the non-special items alphabetically
  nonSpecialItems.sort();
  // quote items can also be sorted if needed, here it's sorted for consistency
  quoteOnlyItems.sort();
  // side coins items can also be sorted if needed, here it's sorted for consistency
  sideOnlyItems.sort();
  // Concatenate the non-special items with the sorted special items
  return [...nonSpecialItems, ...sideOnlyItems, ...quoteOnlyItems];
};
/**
 * This function generates avatars for a given set of wallet items sorted in a custom order.
 * It excludes items identified as side coins.
 *
 * @param {Object|Array} walletArray - An object containing wallet items where keys represent unique identifiers for coins, or an array of coin keys.
 * @returns {JSX.Element} An AvatarGroup component wrapped around individual Avatar components.
 */

export const walletImgRender = (
  walletArray,
  AvatarGroupClasses = 'justify-content-center px-2',
  isAvatar = true,
  avatarSize = 's',
  MAX_DISPLAY = 7
) => {
  // Handle both object and array types for walletArray
  let walletKeys;
  if (Array.isArray(walletArray)) {
    walletKeys = walletArray;
  } else if (typeof walletArray === 'object' && walletArray !== null) {
    walletKeys = Object.keys(walletArray);
  } else {
    throw new Error('Invalid walletArray type');
  }

  // Starts the zIndex for CSS stacking context based on the count of wallet items.
  const zIndexStart = walletKeys.length;

  // Custom sorting function, possibly putting a specific item at the end.
  const sortedWallet = customSort(walletKeys);

  const displayCoins =
    MAX_DISPLAY != null ? sortedWallet.slice(0, MAX_DISPLAY) : sortedWallet;
  const remainingCount =
    MAX_DISPLAY != null ? sortedWallet.length - MAX_DISPLAY : 0;

  // Create the avatar elements
  const avatars = displayCoins.map((coin, index) => {
    return (
      <Avatar
        key={index}
        src={coinsData[coin].img}
        name={coinsData[coin].name}
        isExact
        imgStyle={{ zIndex: zIndexStart - index }}
        size={avatarSize}
      />
    );
  });

  const images = displayCoins.map((coin, index) => {
    return (
      <img
        key={index}
        src={coinsData[coin].img}
        alt={coinsData[coin].name}
        style={{
          height: '20px',
          marginRight: '-0.5rem',
          position: 'relative',
          zIndex: 3 - index
        }}
      />
    );
  });

  // If there are extra coins, add the indicator
  if (remainingCount > 0) {
    const extraIndicator = (
      <div
        key="extra"
        style={{
          height: '25px',
          width: '25px',
          borderRadius: '50%',
          backgroundColor: 'var(--falcon-500)',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          marginLeft: '-5px',
          position: 'relative',
          zIndex: zIndexStart - displayCoins.length,
          fontSize: '12px',
          color: 'white'
        }}
        title={`${remainingCount} more`}
      >
        +{remainingCount}
      </div>
    );
    avatars.push(extraIndicator);
    images.push(extraIndicator);
  }

  // Renders a group of Avatars wrapped in AvatarGroup, centrally aligned with padding on x-axis.
  return isAvatar ? (
    <AvatarGroup className={`${AvatarGroupClasses}`}>{avatars}</AvatarGroup>
  ) : (
    images
  );
};
