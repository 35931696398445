// Bot images
import dynamoImg from 'assets/img/hodlie/botCategory/dynamo.png';
import grizzlyImg from 'assets/img/hodlie/botCategory/grizzly.png';
import deepScalperImg from 'assets/img/hodlie/botCategory/deep.png';
import centImg from 'assets/img/hodlie/botCategory/pixelCent.png';
import generalImg from 'assets/img/hodlie/botCategory/noBot.png';

export const botMedia = {
  takeProfitBot30: {
    img: deepScalperImg,
    anim: () => import('assets/img/animated-icons/DeepScalper.json')
  }, // Deep Scalper
  iBot30: {
    img: dynamoImg,
    anim: () => import('assets/img/animated-icons/Dynamo.json')
  }, // Dynamo
  iBot60: {
    img: grizzlyImg,
    anim: () => import('assets/img/animated-icons/Grizzly.json')
  }, // Grizzly
  takeProfitBot60: {
    img: centImg,
    anim: () => import('assets/img/animated-icons/Centurion.json')
  }, // Centurion
  6: {
    anim: () => import('assets/img/animated-icons/BT.json')
  }, // Market Leaders
  7: {
    anim: () => import('assets/img/animated-icons/BT.json')
  }, // High Volume
  8: {
    anim: () => import('assets/img/animated-icons/YT.json')
  }, // Discovery Altcoins
  general: {
    img: generalImg,
    anim: () => import('assets/img/animated-icons/robot.json')
  } // General Bot
};
