const envType = process.env.REACT_APP_ENV; // Environment type from environment variables
const canTrack = envType === 'PROD'; // Can track Tolt

export const toltSignup = mail => {
  if (canTrack) {
    if (window.tolt_referral) {
      window.tolt.signup(mail);
    }
  }
};
