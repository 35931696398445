import { clarity } from 'react-microsoft-clarity';

// const token_NO_sub = 'n23a516kjp'; // Production token for Clarity - NO sub
const token_SUB = 'n68h95koi8'; // Production token for Clarity - SUB (pro_tier > 0)

const envType = process.env.REACT_APP_ENV; // Environment type from environment variables
const canTrack = envType === 'PROD'; // Can track Clarity

// set tag clarity
const setCustomTag = async data => {
  const checkStarted = () => {
    if (clarity.hasStarted()) {
      window.clarity('set', 'marketingID', data?.tracking_id || 'default');
      window.clarity('set', 'email', data?.email || 'default');
      window.clarity('set', 'proTier', String(data.pro_tier));
      window.clarity(
        'set',
        'isPro',
        data.pro_tier != null ? String(data.pro_tier > 0) : 'default'
      );
    } else {
      setTimeout(checkStarted, 100);
    }
  };

  checkStarted();
};

// Start seeing data on the Clarity dashboard with your id
export const initClarity = (data = null) => {
  if (canTrack) {
    if (!clarity.hasStarted()) clarity.init(token_SUB);
    setCustomTag(data);
  }
};

export const removeClarity = () => {
  if (clarity.hasStarted()) {
    const clarityScript = document.querySelector(
      'script[src*="https://www.clarity.ms/tag/"]'
    );
    if (clarityScript) {
      clarityScript.parentNode.removeChild(clarityScript);
    }
  }
};

// Identify the user
export const identifyClarityUser = (marketingID, userInfos) => {
  if (canTrack) {
    if (clarity.hasStarted()) {
      clarity.identify(marketingID, { mail: userInfos.email });

      // Cookie consent
      clarity.consent();

      // set pro_tier tag
      clarity.setTag('tier', userInfos.pro_tier);
    } else {
      console.error('Clarity has not started');
    }
  }
};
