import step1 from 'assets/img/BinanceGuide/step 1.png';
import step2 from 'assets/img/BinanceGuide/step 2.png';
import step3_ from 'assets/img/BinanceGuide/step 3_.png';
import step3 from 'assets/img/BinanceGuide/step 3.png';
import step4 from 'assets/img/BinanceGuide/step 4.png';
import step5 from 'assets/img/BinanceGuide/step 5.jpg';
import step6 from 'assets/img/BinanceGuide/step 6.png';
import step7 from 'assets/img/BinanceGuide/step 7.png';
import step8 from 'assets/img/BinanceGuide/step 8.png';
import step9 from 'assets/img/BinanceGuide/step 9.png';
import step10 from 'assets/img/BinanceGuide/step 10.png';

export const BinanceGuide = {
  title: {
    'en-EN': 'Binance wallet connection Guide',
    'it-IT': 'Guida al collegamento del portfoglio Binance'
  },
  titleDescription: {
    'en-EN':
      'This guide provides step-by-step instructions on how to create and manage Binance API keys. By following these steps, you will be able to generate API keys and modify your restrictions for improved security and control over your Binance account.',
    'it-IT':
      'Questa guida fornisce istruzioni passo passo su come creare e gestire le chiavi API di Binance. Seguendo questi passaggi, sarai in grado di generare chiavi API e modificare le tue restrizioni per una maggiore sicurezza e controllo sul tuo account Binance.'
  },
  subtitle: {
    'en-EN':
      'API key generation can only be done through the <i>Desktop version</i> of the Binance site. If you are using a mobile device, all you need to do is open a new browser window (e.g. Chrome or Safari), open the settings and click on the "Desktop Site" checkmark, and then smoothly follow the procedure.',
    'it-IT':
      'La generazione delle chiavi API può avvenire solamente tramite la <i>versione Desktop</i> del sito Binance. Se stai utilizzando un dispositivo mobile, ti basterà aprire una nuova finestra del browser (ad esempio Chrome o Safari), aprire le impostazione e cliccare sulla spunta "Sito Desktop", per poi seguire agevolmente la procedura.'
  },
  lastUpdate: {
    'it-IT': 'Ultimo aggiornamento: ',
    'en-EN': 'Last update: '
  },
  sections: [
    {
      name: {
        'it-IT':
          'Crea un account Binance cliccando <a href="https://accounts.binance.com/register" target="_blank" rel="noreferrer"><u>qui</u></a>. Completa la verifica dell’identità sul tuo account ed effettua un <a href="/deposit-binance">deposito</a> (di almeno 200$) su Binance per iniziare il trading su Hodlie. Hai bisogno di ulteriore aiuto? Clicca <a href="https://www.binance.com/it/support/faq/la-guida-per-principianti-a-binance-c780097f75dd450a82d17f1e84153276" target="_blank" rel="noreferrer"><u>qui</u></a>.<br>Sei già iscritto a Binance? Vai al punto 2.',
        'en-EN':
          'Create a Binance account by clicking <a href="https://accounts.binance.com/register" target="_blank" rel="noreferrer"><u>here</u></a>. Complete identity verification on your account and make a <a href="/deposit-binance">deposit</a> (of at least $200) on Binance to begin trading on Hodlie. Need more help? Click <a href="https://www.binance.com/it/support/faq/la-guida-per-principianti-a-binance-c780097f75dd450a82d17f1e84153276" target="_blank" rel="noreferrer"><u>here</u></a>.<br>Are you already subscribed to Binance? Go to step 2.'
      },
      shortName: {
        'it-IT': 'Account Binance',
        'en-EN': 'Binance Account'
      },
      media: step1
    },
    {
      name: {
        'it-IT': `Dopo aver completato con successo lo step precedente, resta collegato su Binance e clicca in alto nella sezione “Profilo”. Nel menù a tendina che si aprirà, clicca su “Account”`,
        'en-EN': `After successfully completing the previous step, remain logged in to Binance and click at the top in the "Profile" section. In the drop-down menu that opens, click on "Account"`
      },
      shortName: {
        'it-IT': 'Profilo',
        'en-EN': 'Profile'
      },
      media: step2
    },
    {
      name: {
        'it-IT': `Nel menù laterale che si aprirà a sinistra, seleziona “Conto” e poi clicca su “Gestione API”.`,
        'en-EN': `In the side menu that opens on the left, select "Account" and then click on "API Management".`
      },
      shortName: {
        'it-IT': 'Gestione API',
        'en-EN': 'API Management'
      },
      media: step3_
    },
    {
      name: {
        'it-IT': `DESELEZIONA la checkbox indicata dalla freccia rossa. Quindi fai click nell'angolo in alto a destra sulla sezione contrassegnata come "Chiave API creata". Da questo punto in poi assicurati che la checkbox sia sempre deselezionata.`,
        'en-EN': `DESELECT the checkbox indicated by the red arrow. Then click on the top right corner of the section marked as "API Key created." From this point on, make sure the checkbox is always deselected.`
      },
      shortName: {
        'it-IT': 'Chiave API',
        'en-EN': 'API Key'
      },
      media: step3
    },
    {
      name: {
        'it-IT': `All'interno del modulo che verrà visualizzato, assicurati che l'opzione "Generata dal Sistema" sia selezionata e poi procedi cliccando sul pulsante "Successivo".`,
        'en-EN': `Inside the displayed form, make sure the "System-generated" option is selected and then proceed by clicking the "Next" button.`
      },
      shortName: {
        'it-IT': 'Genera Chiave',
        'en-EN': 'Generate Key'
      },
      media: step4
    },
    {
      name: {
        'it-IT': `Assegna un nome a questa chiave API (ad esempio, "Hodlie") e continua premendo il pulsante "Successivo".`,
        'en-EN':
          'Assign a name to this API key (e.g., "Hodlie") and continue by pressing the "Next" button.'
      },
      shortName: {
        'it-IT': 'Nome Chiave',
        'en-EN': 'Key Name'
      },
      media: step5
    },
    {
      name: {
        'it-IT': `Successivamente, seleziona l'opzione "Modifica le restrizioni". Assicurati che la casella in alto indicata dalla freccia rossa non sia spuntata.`,
        'en-EN':
          'Next, select the "Edit restrictions" option. Make sure the top box indicated by the red arrow is not checked.'
      },
      shortName: {
        'it-IT': 'Modifica Restrizioni',
        'en-EN': 'Edit restrictions'
      },
      media: step6
    },
    {
      name: {
        'it-IT': `Dopo aver abilitato le modifiche, spunta la casella accanto a "Abilita il trading spot e a margine" e successivamente l’opzione "Accesso senza restrizioni". Clicca su Salva.`,
        'en-EN':
          'After enabling the changes, check the "Enable Spot and Margin trading" box and then the "Unrestricted access" option. Click Save.'
      },
      description: {
        'it-IT': `L'API Key permette ai bot di effettuare transazioni (di compravendita) ma ha una serie di permessi e Hodlie chiede di abilitare solo quello per fare trading. Non è quindi possibile effettuare prelievi e altre operazioni dedicate tramite API Key.`,
        'en-EN':
          'The API Key allows the bots to make transactions (buying and selling) but has a series of permissions and Hodlie asks to enable only the one for trading. It is therefore not possible to make withdrawals and other operations dedicated via API Key.'
      },
      shortName: {
        'it-IT': 'Abilita il trading spot',
        'en-EN': 'Enable Spot Trading'
      },
      media: step7
    },
    {
      name: {
        'it-IT': `Mantieni aperta la pagina di Binance (se la chiudi, non potrai più visualizzare la Secret Key appena creata) e torna su Hodlie alla sezione <a href="/wallet" target="_blank" rel="noreferrer">Wallet</a>, quindi clicca sul pulsante “Collega” accanto alla card Binance.`,
        'en-EN':
          'Keep the Binance page open (if you close it, you will no longer be able to view the Secret Key you just created) and return to Hodlie to the <a href="/wallet" target="_blank" rel="noreferrer">Wallet</a> section, then click on the "Link" button next to the Binance card.'
      },
      shortName: {
        'it-IT': 'Accedi a Wallet',
        'en-EN': 'Go to Wallet'
      },
      media: step8
    },
    {
      name: {
        'it-IT': `Copia le due stringhe precedentemente generate su Binance e incollale nel modulo che si aprirà, poi premi “Conferma”.`,
        'en-EN':
          'Copy the two strings previously generated on Binance and paste them into the form that will open, then press "Confirm.'
      },
      shortName: {
        'it-IT': 'Copia e incolla',
        'en-EN': 'Copy and paste'
      },
      media: step9
    },
    {
      name: {
        'it-IT': `Complimenti, il tuo wallet Binance è stato collegato correttamente! Ora puoi iniziare la tua esperienza di trading alla sezione <a href="/invest">Creazione Bot</a>. Potrai rimuovere il collegamento al tuo wallet in qualsiasi momento, per maggiori informazioni consulta i nostri <a href="/termsandconditions">Termini e Condizioni</a>.`,
        'en-EN': `Congratulations, your Binance wallet has been successfully connected! You can now begin your trading experience in the <a href="/invest">Create Bot</a> section. You can remove the link to your wallet at any time, see our <a href="/termsandconditions">Terms & Conditions</a> for more information.`
      },
      shortName: {
        'it-IT': 'Complimenti',
        'en-EN': 'Congrats'
      },
      media: step10
    }
  ]
};
