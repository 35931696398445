import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Lottie from 'lottie-react';
import { getBotAnimation } from './functions';

const LottieBotLazy = ({ type, ...rest }) => {
  const [animationData, setAnimationData] = useState(null);

  useEffect(() => {
    const loadAnimation = async () => {
      try {
        const animData = await getBotAnimation(type || '');
        setAnimationData(animData);
      } catch (error) {
        console.error('Error during animation loading:', error);
      }
    };

    loadAnimation();
  }, [type]);

  return animationData ? (
    <Lottie animationData={animationData} {...rest} />
  ) : (
    <div style={{ height: '120px' }} />
  );
};

LottieBotLazy.propTypes = {
  type: PropTypes.string
};

export default LottieBotLazy;
