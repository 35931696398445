export const name = 'Hodlie S.r.l.';
export const headquarter = 'Via degli Iris 89/A, 16148, Genova (GE)';
export const fiscalCode = '02862500994';
export const email = 'hello@hodlie.net';
export const supportEmail = 'support@hodlie.net';
export const site = 'www.hodlie.ai';
export const platform = 'app.hodlie.finance';
export const telegramChannel = 'https://t.me/Hodlie';
export const privacyPolicyLink =
  'https://www.iubenda.com/privacy-policy/99598171';

export default [name, email];
