import TagManager from 'react-gtm-module'; // Import TagManager
const envType = process.env.REACT_APP_ENV;

const tagManagerArgs = { gtmId: 'GTM-PF45L65V' };

let gtm = TagManager;

export const initializeTagManager = () => {
  if (envType === 'PROD') TagManager.initialize(tagManagerArgs);
};

export const gtm_event_userRegistered = (trackingID = null) => {
  if (envType === 'PROD')
    gtm.dataLayer({
      dataLayer: {
        event: 'user_registered',
        tracking_id: trackingID
      }
    });
};
