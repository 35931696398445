// images map for news sources
import ambCryptoImg from 'assets/img/hodlie/news/AMBCrypto.png';
import beInCryptoImg from 'assets/img/hodlie/news/BeInCrypto.png';
import benzingaImg from 'assets/img/hodlie/news/Benzinga.png';
import bitcoinImg from 'assets/img/hodlie/news/Bitcoin.png';
import bitcoinMagazineImg from 'assets/img/hodlie/news/BitcoinMagazine.png';
import bitcoinistImg from 'assets/img/hodlie/news/Bitcoinist.png';
import blockonomiImg from 'assets/img/hodlie/news/Blockonomi.png';
import blockworksImg from 'assets/img/hodlie/news/Blockworks.png';
import coincuImg from 'assets/img/hodlie/news/Coincu.png';
import coindeskImg from 'assets/img/hodlie/news/Coindesk.png';
import cointelegraphImg from 'assets/img/hodlie/news/Cointelegraph.png';
import coinGapeImg from 'assets/img/hodlie/news/CoinGape.png';
import coinMarketCapImg from 'assets/img/hodlie/news/CoinMarketCap.png';
import coinPediaImg from 'assets/img/hodlie/news/CoinPedia.png';
import coinspeakerImg from 'assets/img/hodlie/news/Coinspeaker.png';
import coinspressImg from 'assets/img/hodlie/news/Coinspress.png';
import cryptoDailyImg from 'assets/img/hodlie/news/CryptoDaily.png';
import cryptoEconomyImg from 'assets/img/hodlie/news/CryptoEconomy.png';
import cryptoGlobeImg from 'assets/img/hodlie/news/CryptoGlobe.png';
import cryptoHeadlinesImg from 'assets/img/hodlie/news/CryptoHeadlines.png';
import cryptoMufasaImg from 'assets/img/hodlie/news/cryptoMufasa.png';
import cryptoNewsImg from 'assets/img/hodlie/news/CryptoNews.png';
import cryptopolitanImg from 'assets/img/hodlie/news/Cryptopolitan.png';
import cryptoPotatoImg from 'assets/img/hodlie/news/CryptoPotato.png';
import cryptoSlateImg from 'assets/img/hodlie/news/CryptoSlate.png';
import decryptImg from 'assets/img/hodlie/news/Decrypt.png';
import dailycoinImg from 'assets/img/hodlie/news/Dailycoin.png';
import finboldImg from 'assets/img/hodlie/news/Finbold.png';
import forbesImg from 'assets/img/hodlie/news/Forbes.png';
import fxEmpireImg from 'assets/img/hodlie/news/FXEmpire.png';
import invezzImg from 'assets/img/hodlie/news/Invezz.png';
import investorplaceImg from 'assets/img/hodlie/news/Investorplace.png';
import liveBitcoinNewsImg from 'assets/img/hodlie/news/LiveBitcoinNews.png';
import newsBTCImg from 'assets/img/hodlie/news/NewsBTC.png';
import proactiveInvestorsImg from 'assets/img/hodlie/news/ProactiveInvestors.png';
import theBlockImg from 'assets/img/hodlie/news/TheBlock.png';
import theCryptonomistImg from 'assets/img/hodlie/news/TheCryptonomist.png';
import theDailyHodlImg from 'assets/img/hodlie/news/TheDailyHodl.png';
import theNewsCryptoImg from 'assets/img/hodlie/news/TheNewsCrypto.png';
import theMotleyFoolImg from 'assets/img/hodlie/news/TheMotleyFool.png';
import theCurrencyAnalyticsImg from 'assets/img/hodlie/news/theCurrencyAnalytics.png';
import uTodayImg from 'assets/img/hodlie/news/UToday.png';
import zycryptoImg from 'assets/img/hodlie/news/Zycrypto.png';

// All names in lowercase
export const newsImgMap = {
  coingape: coinGapeImg,
  ambcrypto: ambCryptoImg,
  coinmarketcap: coinMarketCapImg,
  cryptoeconomy: cryptoEconomyImg,
  finbold: finboldImg,
  themotleyfool: theMotleyFoolImg,
  beincrypto: beInCryptoImg,
  coinpedia: coinPediaImg,
  cryptoglobe: cryptoGlobeImg,
  forbes: forbesImg,
  thenewscrypto: theNewsCryptoImg,
  benzinga: benzingaImg,
  coincu: coincuImg,
  cryptoheadlines: cryptoHeadlinesImg,
  investorplace: investorplaceImg,
  utoday: uTodayImg,
  bitcoin: bitcoinImg,
  coindesk: coindeskImg,
  cryptopotato: cryptoPotatoImg,
  invezz: invezzImg,
  zycrypto: zycryptoImg,
  bitcoinmagazine: bitcoinMagazineImg,
  coinspeaker: coinspeakerImg,
  cryptoslate: cryptoSlateImg,
  newsbtc: newsBTCImg,
  cryptomufasa: cryptoMufasaImg,
  bitcoinist: bitcoinistImg,
  coinspress: coinspressImg,
  cryptopolitan: cryptopolitanImg,
  proactiveinvestors: proactiveInvestorsImg,
  thecurrencyanalytics: theCurrencyAnalyticsImg,
  blockonomi: blockonomiImg,
  cointelegraph: cointelegraphImg,
  dailycoin: dailycoinImg,
  theblock: theBlockImg,
  blockworks: blockworksImg,
  cryptonews: cryptoNewsImg,
  decrypt: decryptImg,
  thecryptonomist: theCryptonomistImg,
  livebitcoinnews: liveBitcoinNewsImg,
  cryptodaily: cryptoDailyImg,
  thedailyhodl: theDailyHodlImg,
  fxempire: fxEmpireImg
};
