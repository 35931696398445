import { getCookieValue } from 'helpers/utils';

const envType = process.env.REACT_APP_ENV;
var _hsq = (window._hsq = window._hsq || []);

export const hs_sendMailAddress = async (email = '', link = '/') => {
  if (envType === 'PROD') {
    // get trimmet path to send in form API
    const localPageUrl = window.location.href;
    let trimmedPath = link.replace(/^\/|\/$/g, '');
    // send Forms API submission
    const hutk = getCookieValue('hubspotutk');
    const url =
      'https://api.hsforms.com/submissions/v3/integration/submit/144245951/f944d5aa-6696-4eca-bf5e-d725544b3034';

    const data = {
      fields: [
        {
          objectTypeId: '0-1',
          name: 'email',
          value: email
        }
      ],
      context: {
        hutk: hutk,
        pageUri: localPageUrl,
        pageName: 'Hodlie | ' + trimmedPath
      }
    };
    try {
      await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(data)
      });
    } catch (error) {
      console.error('HS Forms API Error:', error.message);
    }

    // send email data using push
    // set the mail
    _hsq.push(['identify', { email: email }]);
    // set the link
    _hsq.push(['setPath', link]);
    // send data to hubspot
    _hsq.push(['trackPageView']);
  }
};
