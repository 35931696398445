import mixpanel from 'mixpanel-browser'; // Import Mixpanel SDK

const token = 'ad25432543da0cd2d794d8ce6e563552'; // Production token for Mixpanel

const envType = process.env.REACT_APP_ENV; // Environment type from environment variables
const canTrack = envType === 'PROD'; // Can track Mixpanel
const isDebug = false; // Debug mode

/**
 * Initializes Mixpanel with a given token.
 * This should be called once per app lifecycle preferably during app start up.
 */
export const initMixpanel = () => {
  if (canTrack) {
    mixpanel.init(token, {
      debug: isDebug,
      track_pageview: 'full-url', // Automatically track full URL pageviews (for single page sites)
      persistence: 'localStorage', // Use local storage for storing Mixpanel data
      cross_subdomain_cookie: true // Allow cookies to be set on subdomains
    });
  }
};

/**
 * Identifies a user with a unique ID (marketingID) and sets their email (use during login o start session)
 * @param {string} marketingID - The unique identifier for the user.
 * @param {string} mail
 */
export const identifyMixpanelUser = (marketingID, mail) => {
  if (canTrack) {
    // use the query parameter to get the distinct id (if come from wp landing page) or use the marketing ID
    const queryParameters = new URLSearchParams(window.location.search);
    const distinctID = queryParameters.get('mp_distinc_id') || marketingID;

    mixpanel.identify(distinctID); // Associate all future events with this distinct_id
    mixpanel.people.set({
      $email: mail
    });
  }
};

/**
 * Resets the current user's Mixpanel session.
 * Useful when logging out a user, to clear the data associated with the previous session.
 */
export const resetUser = () => {
  if (canTrack) {
    mixpanel.reset(); // Reset mixpanel state such as distinct_id
  }
};

/**
 * Tracks an event with a descriptive name and properties.
 * @param {string} eventName - Name of the event to track.
 * @param {object} eventProperties - Additional properties or metadata to associate with this event.
 */
export const trackEvent = (eventName, eventProperties) => {
  if (canTrack) {
    mixpanel.track(eventName, eventProperties); // Sends the event to Mixpanel
  }
};
