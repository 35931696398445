export const errorMsg = {
  subscriptionNotExisting: {
    'en-EN': 'The user has not active subscriptions',
    'it-IT': "L'utente non ha nessun abbonamento attivo"
  },
  subscriptionCreationAlreadyExists: {
    'en-EN': 'The user has already an active subscription',
    'it-IT': "L'utente ha già un abbonamento attivo"
  },
  kycNotApproved: {
    'en-EN': 'The user has not completed KYC yet',
    'it-IT': "L'utente non ha completato la procedura di KYC"
  },
  cannotInitKYC: {
    'en-EN': 'The user cannot init a KYC process',
    'it-IT':
      "L'utente non può inizializzare il processo di KYC, si è verificata la mail?"
  },
  cannotSubscribePro: {
    'en-EN': 'The user cannot subscribe Pro',
    'it-IT': "L'utente non può abbonarsi a Pro, si è verificata la mail?"
  },
  cannotSubscribeTrader: {
    'en-EN': 'Could not subscribe Private plan',
    'it-IT': 'Non è stato possibile abbonarsi a Private'
  },
  maxBotReached: {
    'en-EN': 'Maximum number of bots reached',
    'it-IT': 'Numero massimo di bot raggiunto'
  },
  validation: {
    'en-EN': 'Error during validation: ',
    'it-IT': 'Errore durante la validazione: '
  },
  DBInteraction: {
    'en-EN': 'Error during DB interaction',
    'it-IT': "Errore durante l'interazione con il DB"
  },
  DBInsertion: {
    'en-EN': 'Error during DB insertion',
    'it-IT': "Errore durante l'inserimento nel DB"
  },
  userInfo: {
    'en-EN': 'User information not found',
    'it-IT': 'Informazioni utente non trovate'
  },
  bkSimul: {
    'en-EN': 'Error during backtester simulation',
    'it-IT': 'Errore durante la simulazione del backtester'
  },
  noBot: {
    'en-EN': 'No bots found in DB',
    'it-IT': 'Nessun bot trovato nel DB'
  },
  noCoins: {
    'en-EN': 'No allowed coins found',
    'it-IT': 'Nessuna moneta consentita trovata'
  },
  noExchanges: {
    'en-EN': 'No allowed exchanges found',
    'it-IT': 'Nessun exchange consentito trovato'
  },
  malformedCredentials: {
    'en-EN':
      'Exchange credentials not working: make sure to enable permissions to trade on spot market',
    'it-IT':
      "Credenziali dell'Exchange non funzionanti: assicurati di abilitare le autorizzazioni per fare trading sul mercato spot"
  },
  noUser: { 'en-EN': 'User not found', 'it-IT': 'Utente non trovato' },
  badCredentials: {
    'en-EN': 'Bad Credentials',
    'it-IT': 'Credenziali non valide'
  },
  userProblem: {
    'en-EN': 'A problem has been detected. Please contact the support team',
    'it-IT': 'È stato rilevato un problema. Contatta il team di supporto'
  },
  lowAmount: {
    'en-EN': 'The inserted amount is too low.',
    'it-IT': "L'importo inserito è troppo basso."
  },
  highAmount: {
    'en-EN': 'The inserted amount is too high.',
    'it-IT': "L'importo inserito è troppo alto."
  },
  highWallet: {
    'en-EN': 'The inserted asset amount is too high.',
    'it-IT': "L'importo inserito come asset è troppo alto."
  },
  capitalLimitReached: {
    'en-EN': 'Maximum allowed capital was reached.',
    'it-IT': "E' stato raggiunto il capitale massimo allocato."
  },
  noUserType: {
    'en-EN': 'User type not found in DB',
    'it-IT': 'Tipo di utente non trovato nel DB'
  },
  exchangeInfo: {
    'en-EN': 'Exchange information not found',
    'it-IT': "Informazioni dell'Exchange non trovate"
  },
  exchangeCon: {
    'en-EN': 'Problems in the communication with the exchange',
    'it-IT': "Problemi nella comunicazione con l'Exchange"
  },
  noTokenType: {
    'en-EN': 'No token type found: ',
    'it-IT': 'Nessun tipo di token trovato: '
  },
  noAuth: { 'en-EN': 'Unauthorized', 'it-IT': 'Non autorizzato' },
  expired: { 'en-EN': 'Token expired', 'it-IT': 'Token scaduto' },
  unlogged: { 'en-EN': 'User is not logged', 'it-IT': 'Utente non loggato' },
  connection: {
    'en-EN': 'Connection error:',
    'it-IT': 'Errore di connessione: '
  },
  noData: {
    'en-EN': 'No data found in DB',
    'it-IT': 'Nessun dato trovato nel DB'
  },
  noTransaction: {
    'en-EN': 'No transaction found in DB',
    'it-IT': 'Nessuna transazione trovata nel DB'
  },
  noOrder: {
    'en-EN': 'No order found in DB',
    'it-IT': 'Nessun ordine trovato nel DB'
  },
  noStats: {
    'en-EN': 'No bot stats found in DB',
    'it-IT': 'Nessuna statistica del bot trovata nel DB'
  },
  noBotStatus: {
    'en-EN': 'No BotStatus found in DB',
    'it-IT': 'Nessun BotStatus trovato nel DB'
  },
  botNameProblem: {
    'en-EN': 'The bot name does not respect the rules',
    'it-IT': 'Il nome del bot non rispetta le regole'
  },
  mailAlreadyEntered: {
    'en-EN': 'This email has already been used',
    'it-IT': 'Questa email è già stata utilizzata'
  },
  usernameAlreadyEntered: {
    'en-EN': 'This username has already been used',
    'it-IT': 'Questo nome utente è già stato utilizzato'
  },
  changeUsernameWrongPwd: {
    'en-EN': 'PASSWORD: The password is incorrect',
    'it-IT': 'PASSWORD: La password inserita è errata'
  },
  noExternalReferral: {
    'en-EN': 'REFERRAL: Referral does not exist',
    'it-IT': 'REFERRAL: Il referral non esiste'
  },
  dailyBonusAlreadyTaken: {
    'en-EN': 'Today the daily bonus has been already taken',
    'it-IT': 'Il bonus giornaliero è già stato richiesto oggi'
  },
  UCnotFound: {
    'en-EN': 'UC: Unique Code not found',
    'it-IT': 'UC: Codice univoco non trovato'
  },
  OldPwdIncorrect: {
    'en-EN': 'OLDPWD: Old password is incorrect',
    'it-IT': 'OLDPWD: La vecchia password inserita è errata'
  },
  changePWDErr: {
    'en-EN': 'Error during change password: not update pwd in DB',
    'it-IT': 'Errore durante il cambio della password: non aggiornata nel DB'
  },
  brokenLink: {
    'en-EN':
      'The link used is broken. Please repeat the procedure to change your password',
    'it-IT':
      'Il link utilizzato non funziona. Ripetere la procedura per cambiare la password'
  },
  expiredLink: {
    'en-EN':
      'The links have a duration of 10 minutes and the one used has expired. Please repeat the procedure to change your password',
    'it-IT':
      'I link hanno una durata di 10 minuti e quello utilizzato è scaduto. Ripetere la procedura per cambiare la password'
  },
  usernameNotValid: {
    'en-EN': 'USERNAME: Username can only contain letters and numbers',
    'it-IT': "USERNAME: L'username può contenere solo lettere e numeri"
  },
  usernameNotAvailable: {
    'en-EN': 'USERNAME: This username is not available',
    'it-IT': 'USERNAME: Questo username non è disponibile'
  },
  formNotFound: {
    'en-EN': 'Form not found in request',
    'it-IT': 'Questionario non trovato nella richiesta'
  },
  changeUsernameErr: {
    'en-EN': 'Error during change username: not update in DB',
    'it-IT':
      "Errore durante il cambio dell'username: non aggiornato nel database"
  },
  pushNotificationsCredentialsNotFound: {
    'en-EN': 'User not registered to notifications',
    'it-IT': 'Utente non registrato per le notifiche'
  },
  'noRatings-coin': {
    'en-EN': 'No coin ratings found',
    'it-IT': 'Nessun coin rating trovato'
  },
  'noRatings-exchange': {
    'en-EN': 'No exchange ratings found',
    'it-IT': 'Nessun exchange rating trovato'
  },
  invalid: {
    'en-EN': 'The selected fee is not valid',
    'it-IT': 'La commissione inserita non è valida'
  },
  noBotType: {
    'en-EN': 'Bot type not found',
    'it-IT': 'Tipologia di bot non trovata'
  },
  botNotAllowed: {
    'en-EN': 'Bot type not available',
    'it-IT': 'Tipologia di bot non disponibile'
  },
  botMaxLimitReached: {
    'en-EN': 'The max amount allocated on this bot type was reached',
    'it-IT':
      'È stato raggiunto il limite di capitale per questa tipologia di bot'
  },
  noParams: {
    'en-EN': 'Bot parameter not found',
    'it-IT': 'Parametro del bot non trovato'
  },
  botsForProUser: {
    'en-EN': 'Impossible to start a real bot if you are not a PRO user',
    'it-IT': 'Impossibile far partire un bot reale se non sei un utente PRO'
  },
  unauthorizedSetExchangeCredentials: {
    'en-EN': 'User not allowed to set exchange credentials',
    'it-IT': 'Utente non autorizzato a impostare le credenziali exchange'
  },
  unauthorizedRemoveExchangeCredentials: {
    'en-EN': 'User not allowed to delete exchange credentials',
    'it-IT': 'Utente non autorizzato a rimuovere le credenziali exchange'
  },
  unreadableBnbBurnStatus: {
    'en-EN': 'Impossible to get or set the value of BnbBurnStatus in Exchange',
    'it-IT':
      "Impossibile leggere o modificare il valore di BnbBurnStatus sull'Exchange"
  },
  invalidFee: { 'en-EN': 'Invalid fee', 'it-IT': 'Commissione non valida' },
  emailAlreadyConfirmed: {
    'en-EN': 'The email address is already confirmed',
    'it-IT': "L'indirizzo email è già stato confermato"
  },
  tooManyConfirmationEmails: {
    'en-EN':
      'A confirmation email was already sent, please wait before requesting another one',
    'it-IT':
      "Una mail di conferma è già stata inviata, attendi alcuni minuti prima di richiederne un'altra"
  },
  googleEmailNotVerified: {
    'en-EN': 'The used Google account is not verified',
    'it-IT': "L'utente Google usato non è verificato"
  },
  recaptchaInvalidScore: {
    'en-EN': 'Captcha verification failed, please retry',
    'it-IT': 'Verifica Captcha fallita, riprovare'
  },
  changeUsernameRelogin: {
    'en-EN': 'Please execute a new login before changing username',
    'it-IT': "È necessario effettuare un nuovo login per cambiare l'username"
  },
  genericNotFound: {
    'en-EN': 'Item not found',
    'it-IT': 'Elemento non trovato'
  },
  botMaxLimitPerCoinReached: {
    'en-EN': 'The max number of bots for this pair of coins has been reached',
    'it-IT': 'È stato raggiunto il limite di bot per questa coppia di coin'
  },
  invalidTaxCode: {
    'en-EN': 'The tax code validation was not successfull',
    'it-IT': 'Il codice fiscale inserito non risulta valido'
  },
  invalidBotName: {
    'en-EN': 'The bot name must be alphanumeric, from 3 chars to 8 chars long',
    'it-IT': 'Il nome del bot deve contenere da 3 a 8 caratteri alfanumerici'
  },
  botNameAlreadyUsed: {
    'en-EN': 'You already have a bot with this name',
    'it-IT': 'Hai già un bot con questo nome'
  },
  pushInvalidCredentials: {
    'en-EN': 'Push notification failed!',
    'it-IT': 'Invio delle notifiche fallito!'
  },
  emailNotConfirmed: {
    'en-EN': 'Please confirm the email address before this action!',
    'it-IT':
      'È richiesto confermare la propria email prima di effettuare questa azione'
  },
  tooManyTraderEmails: {
    'en-EN':
      'A Private information email was already sent, please wait before requesting another one',
    'it-IT':
      "Una mail di informazioni per Private è già stata inviata, attendi alcuni minuti prima di richiederne un'altra"
  },
  botOpenEvent: {
    'en-EN':
      'Wait for the current operation on this bot to complete before performing this action',
    'it-IT':
      "Attendi il completamento dell'operazione in corso su questo bot prima di eseguire questa azione"
  },
  category_not_allowed: {
    'en-EN': 'It is not possible to start this type of strategy',
    'it-IT': 'Non è possibile avviare questa tipologia di strategia'
  }
};
