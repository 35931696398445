import {
  userMinLevel,
  firstProTier,
  secondProTier,
  teamLevel,
  FabesLevel
} from 'staticData/common';
// START img import
import BtcImg from 'assets/img/hodlie/coin/bitcoin-btc-logo.png';
import EthImg from 'assets/img/hodlie/coin/ethereum-eth-logo.png';
import BNBImg from 'assets/img/hodlie/coin/BNB.png';
import tetherImg from 'assets/img/hodlie/coin/tether-usdt-logo.png';
import tusdImg from 'assets/img/hodlie/coin/tusd.png';
import fdusdImg from 'assets/img/hodlie/coin/fdusd.png';
import polkadotImg from 'assets/img/hodlie/coin/polkadot-logo.png';
import solanaImg from 'assets/img/hodlie/coin/solana.png';
import cardanoImg from 'assets/img/hodlie/coin/cardano.png';
import rippleImg from 'assets/img/hodlie/coin/ripple.png';
import DogeImg from 'assets/img/hodlie/coin/dogecoin-doge-logo.png';
import LTCImg from 'assets/img/hodlie/coin/litecoin-ltc-logo.png';
import linkImg from 'assets/img/hodlie/coin/chainlink-link-logo.png';
import avaxImg from 'assets/img/hodlie/coin/avalanche-avax-logo.png';
import shibImg from 'assets/img/hodlie/coin/shiba-inu-shib-logo.png';
import polygonImg from 'assets/img/hodlie/coin/polygon-matic-logo.png';
import uniswapImg from 'assets/img/hodlie/coin/uniswap-uni-logo.png';
import usdcImg from 'assets/img/hodlie/coin/usd-coin-usdc-logo.png';

export const currencyMap = {
  $: 'USDT',
  '€': 'EUR'
};

export const coinsData = {
  BTC: {
    code: 'BTC',
    name: 'Bitcoin',
    symbol: '₿',
    color: '#f7931a',
    img: BtcImg,
    decimalPrecision: 8,
    userLevel: userMinLevel,
    tierLevel: firstProTier
  },
  ETH: {
    code: 'ETH',
    name: 'Ethereum',
    symbol: 'Ξ',
    color: '#c6c5d4',
    img: EthImg,
    decimalPrecision: 8,
    userLevel: userMinLevel,
    tierLevel: firstProTier
  },
  BNB: {
    code: 'BNB',
    name: 'Binance Coin',
    symbol: 'Ƀ',
    color: '#f3ba2f',
    img: BNBImg,
    decimalPrecision: 8,
    userLevel: teamLevel,
    tierLevel: firstProTier
  },
  USDT: {
    code: 'USDT',
    name: 'Tether',
    symbol: '₮',
    color: '#26a17b',
    img: tetherImg,
    decimalPrecision: 2,
    userLevel: userMinLevel,
    tierLevel: firstProTier
  },
  TUSD: {
    code: 'TUSD',
    name: 'TrueUSD',
    symbol: '₮',
    color: '#2479cc',
    img: tusdImg,
    decimalPrecision: 2,
    userLevel: teamLevel,
    tierLevel: firstProTier
  },
  USDC: {
    code: 'USDC',
    name: 'USD Coin',
    symbol: '',
    color: '#26a17b',
    img: usdcImg,
    decimalPrecision: 2,
    userLevel: userMinLevel,
    tierLevel: firstProTier
  },
  SOL: {
    code: 'SOL',
    name: 'Solana',
    symbol: '◎',
    color: '#9945FF',
    img: solanaImg,
    decimalPrecision: 5,
    userLevel: FabesLevel,
    tierLevel: secondProTier
  },
  XRP: {
    code: 'XRP',
    name: 'Ripple',
    symbol: '✕',
    color: '#ffffff',
    img: rippleImg,
    decimalPrecision: 6,
    userLevel: teamLevel,
    tierLevel: firstProTier
  },
  ADA: {
    code: 'ADA',
    name: 'Cardano',
    symbol: '₳',
    color: '#3cc8c8',
    img: cardanoImg,
    decimalPrecision: 3,
    userLevel: teamLevel,
    tierLevel: firstProTier
  },
  DOT: {
    code: 'DOT',
    name: 'Polkadot',
    symbol: '⭕',
    color: '#e6007a',
    img: polkadotImg,
    decimalPrecision: 3,
    userLevel: teamLevel,
    tierLevel: firstProTier
  },
  FDUSD: {
    code: 'FDUSD',
    name: 'FirstDigitalUSD',
    symbol: '$',
    color: '#26A17B', // gpt: #ffa500
    img: fdusdImg,
    decimalPrecision: 2,
    userLevel: teamLevel,
    tierLevel: firstProTier
  },
  DOGE: {
    code: 'DOGE',
    name: 'Dogecoin',
    symbol: '🐶',
    color: '#C2A633',
    img: DogeImg,
    decimalPrecision: 8,
    userLevel: userMinLevel,
    tierLevel: firstProTier
  },
  LINK: {
    code: 'LINK',
    name: 'Chainlink',
    symbol: '🔗',
    color: '#375BD2',
    img: linkImg,
    decimalPrecision: 3,
    userLevel: userMinLevel,
    tierLevel: firstProTier
  },
  AVAX: {
    code: 'AVAX',
    name: 'Avalanche',
    symbol: '',
    color: '#E84142',
    img: avaxImg,
    decimalPrecision: 5,
    userLevel: userMinLevel,
    tierLevel: firstProTier
  },
  LTC: {
    code: 'LTC',
    name: 'Litecoin',
    symbol: 'Ł',
    color: '#BEBEBE',
    img: LTCImg,
    decimalPrecision: 8,
    userLevel: userMinLevel
  },
  SHIB: {
    code: 'SHIB',
    name: 'Shiba Inu',
    symbol: '',
    color: '#FEC807',
    img: shibImg,
    decimalPrecision: 2,
    userLevel: userMinLevel,
    tierLevel: firstProTier
  },
  MATIC: {
    code: 'MATIC',
    name: 'Polygon',
    symbol: '',
    color: '#8248E5',
    img: polygonImg,
    decimalPrecision: 3,
    userLevel: userMinLevel,
    tierLevel: firstProTier
  },
  UNI: {
    code: 'UNI',
    name: 'Uniswap',
    symbol: '',
    color: '#FF007A',
    img: uniswapImg,
    decimalPrecision: 8,
    userLevel: userMinLevel,
    tierLevel: firstProTier
  }
};

// Coins used as quote in every bot
export const asQuoteCoins = [
  coinsData.USDT.code,
  coinsData.FDUSD.code,
  coinsData.TUSD.code
];

// Coins used as side (fee)
export const asSideCoins = [coinsData.BNB.code];
