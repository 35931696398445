import {
  name,
  headquarter,
  fiscalCode,
  email,
  site,
  privacyPolicyLink,
  telegramChannel
} from 'staticData/hodlieInfos';

export const TermsAndConditions = {
  title: {
    'en-EN': 'Terms & Conditions',
    'it-IT': 'Termini e Condizioni'
  },
  onThisPage: {
    'en-EN': 'On this page',
    'it-IT': 'In Questa Pagina'
  },
  lastUpdate: {
    'it-IT': 'Ultimo aggiornamento: ',
    'en-EN': 'Last update: '
  },
  sections: [
    {
      link: 'application',
      name: {
        'it-IT': 'Ambito di applicazione',
        'en-EN': 'Field of application'
      },
      texts: [
        {
          'it-IT':
            'Le presenti condizioni generali (di seguito, i "<b>Termini e Condizioni</b>") si applicano a tutti i contratti conclusi tra <b>' +
            name +
            '</b>, con sede legale in ' +
            headquarter +
            ', iscritta al Registro delle Imprese di Genova con il n. ' +
            fiscalCode +
            ', P.IVA n. ' +
            fiscalCode +
            ', e-mail <a href="mailto:' +
            email +
            '">' +
            email +
            '</a>, (di seguito, "<b>Hodlie</b>"), e l’utente inteso come consumatore finale (di seguito, "<b>Utente</b>") che si abbona alla piattaforma di Hodlie e utilizza i relativi trading bot (di seguito, il “<b>Servizio</b>”) attraverso il sito <a href=' +
            'https://' +
            site +
            ' target="_blank" rel="noreferrer">' +
            site +
            '</u></a> (di seguito, il "<b>Sito</b>"). ',
          'en-EN':
            'The following general terms and conditions (hereinafter, the "<b>Terms and Conditions</b>") apply to all contracts concluded between <b>' +
            name +
            '</b>, with registered office in ' +
            headquarter +
            ', registered with the Genoa Chamber of Commerce under number ' +
            fiscalCode +
            ', VAT number ' +
            fiscalCode +
            ', e-mail address <a href="mailto:' +
            email +
            '">' +
            email +
            '</a>, (hereinafter, "<b>Hodlie</b>"), and the user referred to as the end consumer (hereinafter, "<b>User</b>") who subscribes to the Hodlie platform and uses the related trading bots (hereinafter, the "<b>Service</b>") through the website <a href=' +
            'https://' +
            site +
            ' target="_blank" rel="noreferrer">' +
            site +
            '</u></a> (hereinafter, the "<b>Site</b>"). '
        },
        {
          'it-IT':
            'Il Servizio consiste in un sistema di trading automatico su crypto-attività come meglio descritto nella Clausola 8.2, che potrebbe essere disciplinato da normative locali diverse a seconda del paese in cui l’Utente risiede, è domiciliato o da cui si collega al Sito. Per tale ragione, e considerati i limiti normativi previsti in alcuni paesi dell’Unione Europea, il Sito e il Servizio sono accessibili esclusivamente dall’Italia. Qualora l’Utente non si trovi in Italia, il Sito potrà non essere raggiungibile.',
          'en-EN':
            'The Service consists of an automatic trading system for crypto-assets as better described in Clause 8.2, which may be subject to different local regulations depending on the country where the User resides, is domiciled, or connects to the Site. For this reason, and considering the regulatory limitations in some countries of the European Union, the Site and the Service are accessible exclusively from Italy. If the User is not located in Italy, the Site may not be accessible.'
        },
        {
          'it-IT':
            'L’Utente si impegna a rispettare i presenti Termini e Condizioni in relazione ad ogni utilizzo del Servizio. L’Utente non potrà accedere al Servizio in caso di mancata accettazione dei presenti Termini e Condizioni.',
          'en-EN':
            'The User undertakes to comply with these Terms and Conditions in relation to each use of the Service. The User will not be able to access the Service in case of non-acceptance of these Terms and Conditions.'
        },
        {
          'it-IT':
            'Durante la procedura di iscrizione al Servizio, l’Utente dovrà accettare i presenti Termini e Condizioni che possono essere visualizzati, salvati sul proprio dispositivo o stampati da parte dell’Utente. In ogni caso l’Utente potrà accedere ai Termini e Condizioni aggiornati tramite il Sito.',
          'en-EN':
            'During the registration process for the Service, the User must accept these Terms and Conditions, which can be displayed, saved on their device, or printed by the User. In any case, the User will be able to access the updated Terms and Conditions through the Site.'
        }
      ]
    },
    {
      link: 'changes',
      name: {
        'it-IT': 'Modifiche ai Termini e Condizioni',
        'en-EN': 'Changes to T&C'
      },
      texts: [
        {
          'it-IT':
            'Hodlie si riserva il diritto di apportare modifiche ai presenti Termini e Condizioni laddove tali modifiche dovessero rendersi necessarie per conformarsi a disposizioni di legge. Eventuali modifiche dei Termini e Condizioni saranno notificate con adeguato anticipo e la versione aggiornata dei Termini e Condizioni verrà pubblicata di volta in volta sul Sito.',
          'en-EN':
            'Hodlie reserves the right to make changes to these Terms and Conditions where such changes are necessary to comply with legal provisions. Any changes to the Terms and Conditions will be notified well in advance, and the updated version of the Terms and Conditions will be published from time to time on the Site.'
        }
      ]
    },
    {
      link: 'purchase',
      name: {
        'it-IT': 'Modalità di acquisto del Servizio',
        'en-EN': 'Methods of purchasing the Service'
      },
      texts: [
        {
          'it-IT':
            'Per procedere all’acquisto del Servizio, l’Utente dovrà creare un account personale sul Sito. Laddove l’Utente si sia già registrato in precedenza, l’Utente dovrà accedere al proprio account con le apposite credenziali di autenticazione (i.e. e-mail e password).',
          'en-EN':
            'To proceed with the purchase of the Service, the User must create a personal account on the Site. If the User has already registered previously, the User must log in to their account using the appropriate authentication credentials (i.e. email and password).'
        },
        {
          'it-IT':
            'Per maggiori informazioni sulla creazione di un nuovo account sul Sito le relative regole d’uso, si prega di fare riferimento alle <b><a href="/termsofuse" target="_blank" rel="noreferrer"><u>Condizioni di utilizzo</u></a></b> del Sito.',
          'en-EN':
            'For more information on creating a new account on the Site and the related rules of use, please refer to the <b><a href="/termsofuse" target="_blank" rel="noreferrer"><u>Terms of Use</u></a></b> of the Site.'
        },
        {
          'it-IT':
            'A seguito dell’accesso al proprio account personale, per sottoscrivere un abbonamento al Servizio, l’Utente dovrà inoltrare una richiesta a Hodlie (di seguito, “<b>Ordine di abbonamento</b>”) previa identificazione ai sensi della disciplina vigente sul contrasto all’attività di riciclaggio applicabile ai prestatori di servizi su crypto-attività',
          'en-EN':
            'Following access to their personal account, to subscribe to a Service subscription, the User must submit a request to Hodlie (hereinafter, "<b>Subscription Order</b>") following identification in accordance with the current regulations on anti-money laundering applicable to crypto-asset service providers.'
        },
        {
          'it-IT':
            'Prima di inviare l’Ordine, l’Utente è tenuto a verificare che tutti i dati forniti siano corretti. Eventuali errori di inserimento dei dati potranno essere corretti utilizzando le apposite funzioni di modifica dei dati presenti sul Sito. In caso di errori di cui l’Utente si sia accorto solo dopo aver inoltrato l’Ordine di abbonamento, l’Utente potrà correggerli contattando direttamente Hodlie con le modalità indicate alla successiva Clausola 17.',
          'en-EN':
            'Before submitting the Order, the User must ensure that all provided data is correct. Any errors in data entry can be corrected using the appropriate data editing functions on the Site. In case of errors noticed by the User only after submitting the Subscription Order, the User can correct them by contacting Hodlie directly as indicated in the subsequent Clause 17.'
        },
        {
          'it-IT':
            'A seguito dell’inoltro dell’Ordine di abbonamento a Hodlie da parte dell’Utente tramite il Sito, l’Utente riceverà da Hodlie una e-mail riepilogativa dell’abbonamento al Servizio acquistato, delle condizioni economiche, nonché dei presenti Termini e Condizioni. Tale e-mail avrà valore di conferma dell’accettazione dell’Ordine di abbonamento da parte di Hodlie e delle relative condizioni.',
          'en-EN':
            'Following the submission of the Subscription Order to Hodlie by the User through the Site, the User will receive an email from Hodlie summarizing the subscription to the purchased Service, the financial conditions, as well as the present Terms and Conditions. This email will serve as confirmation of Hodlie’s acceptance of the Subscription Order and its conditions.'
        }
      ]
    },
    {
      link: 'conclusion',
      name: {
        'it-IT': 'Conclusione del contratto',
        'en-EN': 'Conclusion of contract'
      },
      texts: [
        {
          'it-IT':
            'Il Contratto è un contratto concluso a distanza ai sensi dell’art. 50 e ss. del D. Lgs. 6 settembre 2005, n. 206 (di seguito, il “<b>Codice del Consumo</b>”) e ha per oggetto l’abbonamento dell’Utente alla piattaforma di trading di criptovalute con trading bot di Hodlie, a fronte del pagamento di un corrispettivo (il "<b>Contratto</b>").',
          'en-EN':
            'The Contract is a distance concluded contract according to art. 50 et seq. of Legislative Decree of 6 September 2005, n. 206 (hereinafter, the "<b>Consumer Code</b>") and concerns the User\'s subscription to the Hodlie cryptocurrency trading platform with trading bots, in exchange for payment of compensation (the "<b>Contract</b>").'
        },
        {
          'it-IT':
            'Il Contratto è concluso al momento dell’accettazione dell’Ordine da parte di Hodlie ossia con la ricezione dell’e-mail di conferma dell’Ordine di abbonamento da parte di Hodlie, come indicato alla precedente Clausola 3.5. Solo al momento dell’accettazione dell’Ordine da parte di Hodlie, Hodlie procederà ad addebitare all’Utente il corrispettivo per l’abbonamento.',
          'en-EN':
            'The Contract is concluded at the moment of acceptance of the Order by Hodlie, i.e. upon receipt of the confirmation email of the subscription order from Hodlie, as indicated in the previous Clause 3.5. Only upon acceptance of the Order by Hodlie, Hodlie will proceed to charge the User for the subscription.'
        },
        {
          'it-IT':
            'Hodlie si riserva la facoltà di non accettare eventuali richieste in caso di mancata autorizzazione di pagamento o successivo storno dell’importo corrisposto da parte del gestore del metodo di pagamento utilizzato ai sensi della Clausola 7. sotto. In tal caso, l’Utente verrà prontamente informato da Hodlie tramite e-mail.',
          'en-EN':
            'Hodlie reserves the right not to accept any requests in case of non-authorization of payment or subsequent chargeback of the amount paid by the payment method provider used as per Clause 7. below. In such cases, the User will be promptly informed by Hodlie via email.'
        },
        {
          'it-IT':
            'L’Utente è tenuto a conservare il numero di Ordine di abbonamento presente nell’e-mail di conferma di Hodlie per poter accedere al servizio di assistenza e per ogni altra comunicazione con Hodlie.',
          'en-EN':
            'The User is required to keep the Subscription Order number in the confirmation email from Hodlie in order to access customer support and for any other communication with Hodlie.'
        }
      ]
    },
    {
      link: 'subscription',
      name: {
        'it-IT': 'Formule di Abbonamento e durata dell’Abbonamento',
        'en-EN': 'Subscription Formulas and Subscription duration'
      },
      texts: [
        {
          'it-IT':
            'In base all’ammontare del capitale utilizzato dall’Utente in relazione al Servizio, sono previste diverse formule di abbonamento a cui l’Utente può aderire (l’”Abbonamento”). In particolare, l’Utente potrà scegliere tra le seguenti formule di Abbonamento:<ul><li>Explorer</li><li>Investor</li><li>Private</li></ul>',
          'en-EN':
            'Based on the amount of capital used by the User in relation to the Service, there are different subscription formulas that the User can subscribe to (the "Subscription"). In particular, the User can choose from the following Subscription formulas:<ul><li>Explorer</li><li>Investor</li><li>Private</li></ul>'
        },
        {
          'it-IT':
            'Le diverse formule di Abbonamento si distinguono, oltre che per il costo, per possibili limitazioni nella fruizione del Servizio come, a titolo esemplificativo e non esaustivo, limiti all’ammontare di capitale massimo soggetto a gestione o alla tipologia e numero di Bot selezionabili, limiti alla gamma di crypto-attività oggetto del Servizio, minore sofisticatezza o profondità e dettaglio dei dati di performance, della reportistica e grafici, fermi gli obblighi di legge sul contenuto delle informazioni dovute all’Utente. L’Utente può trovare una descrizione dettagliata delle diverse formule di Abbonamento nella sezione <a href="/pricing"><b><u>Abbonamenti</u></b></a> disponibile sul Sito.',
          'en-EN':
            'The different Subscription formulas differ not only in cost but also in possible limitations in the use of the Service, such as, by way of example and not exhaustively, limits on the maximum amount of capital subject to management or the type and number of selectable Bots, limits on the range of crypto-assets covered by the Service, lower sophistication or depth and detail of performance data, reporting, and graphs, without prejudice to the legal obligations regarding the content of information due to the User. The User can find a detailed description of the different Subscription formulas in the <a href="/pricing"><b><u>Subscriptions</u></b></a> section available on the Site.'
        },
        {
          'it-IT':
            'Per ciascuna formula di Abbonamento indicata alla Clausola 5.1. sopra, l’Utente potrà scegliere la durata dell’Abbonamento tra:<ul><li>Mensile</li><li>Annuale</li></ul>',
          'en-EN':
            'For each Subscription formula indicated in Clause 5.1. above, the User can choose the Subscription duration between:<ul><li>Monthly</li><li>Annual</li></ul>'
        },
        {
          'it-IT':
            'L’Abbonamento al Servizio decorre dal giorno della ricezione dell’Ordine di abbonamento dell’Utente e avrà la durata selezionata dall’Utente, come previsto ai sensi della Clausola 5.3. che precede. Al momento dell’Abbonamento, l’Utente beneficerà di un periodo di prova gratuito di giorni 7 dall’attivazione del Servizio, al termine del quale l’Abbonamento prescelto verrà automaticamente attivato e addebitato. L’Utente ha facoltà, nei 7 giorni del periodo di prova, di non procedere con l’attivazione definitiva dell’Abbonamento seguendo la procedura presente sul Sito.',
          'en-EN':
            "The Subscription to the Service starts from the day of receipt of the User's subscription order and will have the duration selected by the User, as provided under Clause 5.3. above. At the time of Subscription, the User will benefit from a free trial period of 7 days from the activation of the Service, after which the chosen Subscription will be automatically activated and charged. During the 7 days of the trial period, the User has the option not to proceed with the final activation of the Subscription by following the procedure on the Site."
        },
        {
          'it-IT':
            'L’Abbonamento si intenderà tacitamente rinnovato allo scadere del periodo selezionato, per un periodo di uguale durata, ferma la possibilità di disattivare il rinnovo automatico in ogni momento nonché recedere dal Contratto secondo le modalità stabilite dalla Clausola 13.',
          'en-EN':
            'The Subscription will be tacitly renewed at the end of the selected period, for a period of equal duration, subject to the possibility of deactivating automatic renewal at any time, as well as terminating the Contract in accordance with the procedures set out in Clause 13.'
        },
        {
          'it-IT':
            'Ove il capitale gestito dell’Utente dovesse superare il limite previsto per ciascuna formula di Abbonamento a cui l’Utente ha precedentemente aderito, come indicata nel primo Ordine di abbonamento, l’Utente riceverà da Hodlie una comunicazione in cui verrà proposto all’Utente di abbonarsi alla formula di Abbonamento successiva. Fermo restando quanto previsto alla Clausola 5.7. che segue, l’Utente potrà decidere liberamente se cambiare formula di Abbonamento.',
          'en-EN':
            "If the User's managed capital were to exceed the limit set for each Subscription formula to which the User has previously subscribed, as indicated in the first subscription order, the User will receive a communication from Hodlie proposing that the User subscribe to the next Subscription formula. Subject to what is provided in Clause 5.7. below, the User is free to decide whether to change the Subscription formula."
        },
        {
          'it-IT':
            'Ove l’Utente non dovesse accettare di cambiare formula di Abbonamento come proposto da Hodlie ai sensi della precedente Clausola 5.6., l’Utente dovrà ridurre il capitale gestito al fine di rispettare i limiti previsti dalla formula di Abbonamento indicata nell’Ordine di abbonamento, fatta salva la possibilità per l’Utente di recedere dal Contratto secondo le modalità stabilite dalla Clausola 13.',
          'en-EN':
            "If the User does not accept to change the Subscription formula as proposed by Hodlie under the previous Clause 5.6., the User must reduce the managed capital in order to comply with the limits set by the Subscription formula indicated in the subscription order, without prejudice to the User's right to terminate the Contract in accordance with the procedures set out in Clause 13."
        }
      ]
    },
    {
      link: 'rates',
      name: {
        'it-IT': 'Tariffe di Abbonamento',
        'en-EN': 'Subscription Rates'
      },
      texts: [
        {
          'en-EN':
            "The Subscription Rates for the Service shown on the Site are expressed in Euros and are inclusive of VAT (hereinafter, the '<b>Subscription Rates</b>'). The Subscription Rates applicable to the User are those published on the Site at the time the Subscription Order is submitted. Such rates may be subject to change over time and it is the User's responsibility to verify the final rate before submitting the Subscription Order",
          'it-IT':
            'Le tariffe di abbonamento al Servizio riportate nel Sito sono espresse in Euro e si intendono comprensive di IVA (di seguito, le "<b>Tariffe di Abbonamento</b>"). Le Tariffe di Abbonamento applicabili all’Utente sono quelle pubblicate sul Sito al momento dell’inoltro dell’Ordine di abbonamento. Tali tariffe possono essere soggette a variazioni nel tempo ed è onere dell’Utente verificare la tariffa finale prima di inoltrare l’Ordine di abbonamento.'
        },
        {
          'en-EN':
            'Unless otherwise specified, any price changes shall be effective as of the beginning of the next Subscription Period. Any User who does not wish to accept a change in the Subscription and/or Subscription Rates shall be entitled to terminate the Subscription, without additional charge, in the manner set forth in Clause 13.3 below.',
          'it-IT':
            "Salvo indicazione contraria, le eventuali variazioni di prezzo saranno efficaci a partire dall'inizio del periodo di abbonamento successivo. L'Utente che non intende accettare una modifica dell'Abbonamento e/o dele Tariffe di Abbonamento ha facoltà di recedere dall'Abbonamento, senza oneri aggiuntivi, secondo le modalità indicate alla successiva Clausola 13.3."
        },
        {
          'it-IT':
            'Fermo restando quanto previsto alla Clausola 5.2. sopra, le informazioni dettagliate sulle caratteristiche delle singole Tariffe di Abbonamento sono disponibili alla pagina <a href="/pricing"><b><u>Tariffe di Abbonamento</u></b></a> presente sul Sito.',
          'en-EN':
            'Subject to what is provided in Clause 5.2 above, detailed information on the characteristics of individual Subscription Rates is available on the <a href="/pricing"><b><u>Subscription Rates</u></b></a> page on the Site.'
        }
      ]
    },
    {
      link: 'payments',
      name: {
        'it-IT': 'Modalità di pagamento',
        'en-EN': 'Payment methods'
      },
      texts: [
        {
          'it-IT':
            "La Tariffa di Abbonamento dovrà essere corrisposta al momento dell’Ordine di abbonamento. Il pagamento dovrà avvenire unicamente per mezzo del Sito tramite le seguenti modalità di pagamento a disposizione dell'Utente: <a href='https://stripe.com/' target='_blank' rel='noreferrer'>Stripe</a>.",
          'en-EN':
            "The Subscription Rate must be paid at the time of the subscription order. Payment must be made solely through the Site using the following payment methods available to the User: <a href='https://stripe.com/' target='_blank' rel='noreferrer'>Stripe</a>."
        },
        {
          'it-IT':
            'Hodlie si riserva di non accettare l’Ordine di abbonamento nel caso di mancata autorizzazione di pagamento o successivo storno dell’importo corrisposto da parte del gestore del metodo di pagamento utilizzato ai sensi della Clausola 7.1. che precede.',
          'en-EN':
            'Hodlie reserves the right not to accept the subscription order in the event of non-authorization of payment or subsequent refund of the amount paid by the payment method provider used in accordance with the preceding Clause 7.1.'
        },
        {
          'it-IT':
            'In caso di annullamento dell’Ordine di abbonamento nei casi espressamente previsti dai presenti Termini e Condizioni, Hodlie richiederà contestualmente l’annullamento della transazione. I tempi di annullamento dipendono esclusivamente dal sistema di pagamento utilizzato. Una volta effettuato l’annullamento della transazione, in nessun caso Hodlie potrà essere ritenuta responsabile per eventuali danni, diretti o indiretti, provocati da ritardo nel mancato rimborso dell’importo da parte del sistema di pagamento utilizzato.',
          'en-EN':
            'In the event of cancellation of the subscription order in cases expressly provided for by these Terms and Conditions, Hodlie will also request the cancellation of the transaction. The cancellation times depend solely on the payment system used. Once the transaction is cancelled, under no circumstances will Hodlie be liable for any damages, direct or indirect, caused by delays in the non-refund of the amount by the payment system used.'
        },
        {
          'it-IT':
            'In caso di rimborsi, gli eventuali importi corrisposti dall’Utente a Hodlie saranno restituiti utilizzando lo stesso metodo di pagamento impiegato dall’Utente per acquistare il Servizio. I rimborsi saranno processati nei tempi e nei modi previsti dal gestore di tale metodo di pagamento.',
          'en-EN':
            'In case of refunds, any amounts paid by the User to Hodlie will be refunded using the same payment method used by the User to purchase the Service. Refunds will be processed according to the times and methods provided by the manager of that payment method.'
        }
      ]
    },
    {
      link: 'termsofuse',
      name: {
        'it-IT': 'Condizioni di utilizzo e fornitura del Servizio',
        'en-EN': 'Terms of Use and Delivery of the Service'
      },
      texts: [
        {
          'it-IT':
            'Per poter usufruire del Servizio, è necessario che l’Utente:',
          'en-EN':
            'In order to use the Service, it is necessary that the User:',
          subtexts: [
            {
              'it-IT':
                'sia un utente già registrato su un exchange di cripto-attività tra quelli accettati da Hodlie per la prestazione del Servizio (quali, a titolo esemplificativo ma non esaustivo, Binance – per maggiori informazioni, si prega di visitare il <a href="https://www.binance.com" target="_blank" rel="noreferrer">sito<a>) (“<b>Exchange</b>”); la lista degli exchange di cripto-attività accettati da Hodlie è disponibile sul Sito. L’Utente, prima di inoltrare l’Ordine di Abbonamento, è tenuto a verificare che l’exchange di crypto-attività a cui intende associare il Servizio sia tra quelli accettati da Hodlie. Nel caso in cui l’exchange non sia tra quelli accettati da Hodlie, non sarà in alcun modo possibile per Hodlie prestare il Servizio; Hodlie non presta alcun servizio di custodia e deposito delle crypto-attività;',
              'en-EN':
                'be a user already registered on a cryptocurrency exchange accepted by Hodlie for the provision of the Service (such as, but not limited to, Binance – for more information, please visit the <a href="https://www.binance.com" target="_blank" rel="noreferrer">website</a>) (“<b>Exchange</b>”); the list of cryptocurrency exchanges accepted by Hodlie is available on the Site. The User, before submitting the Subscription Order, must verify that the cryptocurrency exchange to which they intend to associate the Service is among those accepted by Hodlie. In case the exchange is not among those accepted by Hodlie, it will not be possible for Hodlie to provide the Service; Hodlie does not provide any custody and deposit services for the cryptocurrencies;'
            },
            {
              'it-IT':
                'abbia generato una apposita <i>application program interface key</i> (“<b>API Key</b>”), secondo le istruzioni fornite e reperibili sul <a href="/connect-binance-wallet"><b><u>Sito</u></b></a>, con la quale, tramite linguaggio di programmazione, Hodlie possa interfacciarsi con ed operare su l’Exchange presso il quale l’Utente è iscritto;',
              'en-EN':
                'have generated a dedicated <i>application program interface key</i> (“<b>API Key</b>”), according to the instructions provided and available on the <a href="/connect-binance-wallet"><b><u>Site</u></b></a>, with which, through programming language, Hodlie can interface with and operate on the Exchange to which the User is subscribed;'
            },
            {
              'it-IT': 'inserisca sul Sito l’API Key generata;',
              'en-EN': 'enter the generated API Key on the Site;'
            },
            {
              'it-IT':
                'scelga un o più trading bot tra quelli messi a disposizione da Hodlie (“<b>Bot</b>”), che potrebbe essere basato su intelligenza artificiale, con il quale fare trading su un determinato quantitativo di coppie di crypto-attività (quali, a titolo esemplificativo ma non esaustivo, BTC-USDT, i.e. Bitcoin-Tether, stablecoin ancorata al valore del dollaro americano); e',
              'en-EN':
                'choose one or more trading bots from those made available by Hodlie (“<b>Bot</b>”), which could be based on artificial intelligence, to trade a specific quantity of cryptocurrency pairs (such as, but not limited to, BTC-USDT, i.e. Bitcoin-Tether, a stablecoin anchored to the value of the US dollar); and'
            },
            {
              'it-IT':
                'disponga sul proprio conto presso l’Exchange della quantità di crypto-attività necessaria per effettuare gli ordini di acquisto e vendita tramite Bot.',
              'en-EN':
                'have on their account at the Exchange the amount of cryptocurrency necessary to place buy and sell orders through the Bot.'
            }
          ]
        },
        {
          'it-IT':
            'Le caratteristiche del Servizio, che l’Utente accetta, sono le seguenti:',
          'en-EN':
            'The characteristics of the Service, which the User accepts, are as follows:',
          subtexts: [
            {
              'it-IT':
                'Hodlie mette a disposizione dell’Utente diverse categorie di Bot con grado di rischio basso, medio e alto. A titolo esemplificativo, una strategia di trading a rischio basso si prefigge l’obbiettivo di mantenere una esposizione minore, in termini di tempo e volumi, su crypto-attività più volatili (i.e. bitcoin ed Ether) ed una maggiore su crypto-attività con valore di mercato più stabile. Al contrario, una strategia di trading a rischio alto si prefigge l’obbiettivo di mantenere una esposizione più prolungata su crypto-attività più volatili, sia in momento di mercato ribassisti che rialzisti; determinate categorie di Bot potrebbero richiedere un minimo quantitativo di crypto-attività da affidare in gestione al Servizio;',
              'en-EN':
                'Hodlie offers the User different categories of Bots with low, medium, and high risk levels. As an example, a low-risk trading strategy aims to maintain a smaller exposure, in terms of time and volume, to more volatile cryptocurrencies (i.e., bitcoin and Ether) and a higher exposure to cryptocurrencies with a more stable market value. On the contrary, a high-risk trading strategy aims to maintain a more prolonged exposure to more volatile cryptocurrencies, both in bearish and bullish market conditions; certain Bot categories might require a minimum quantity of cryptocurrencies to be entrusted to the Service;'
            },
            {
              'it-IT':
                'in ragione dell’evoluzione normativa sui servizi su crypto-attività, Hodlie si riserva in futuro di limitare la facoltà di scelta del grado di rischio del Bot a seconda del profilo di rischio dell’Utente;',
              'en-EN':
                'due to the evolving regulations on cryptocurrency services, Hodlie reserves the right to limit the option to choose the risk level of the Bot based on the User’s risk profile in the future;'
            },
            {
              'it-IT':
                'Hodlie non presta alcuna garanzia all’Utente circa il fatto che il o i Bot prescelti conseguano risultati di performance migliori rispetto ad una strategia di trading manuale o autonoma da parte dell’Utente né rispetto a ipotesi di mancata movimentazione del portafoglio sul breve, medio e lungo periodo; in particolare l’Utente accetta espressamente il rischio che i risultati della strategia automatica di trading eseguita dal Bot possano, anche in ragione dei costi di transazione, (a) registrare performance inferiori rispetto ad eventuali rialzi del mercato, (b) determinare perdite superiori rispetto all’andamento del mercato; ',
              'en-EN':
                'Hodlie does not provide any guarantee to the User regarding the fact that the chosen Bot(s) will achieve better performance results than a manual or autonomous trading strategy by the User, nor compared to scenarios where the portfolio remains untouched over the short, medium, and long term; in particular, the User expressly accepts the risk that the results of the automatic trading strategy executed by the Bot may, also due to transaction costs, (a) achieve lower performance compared to possible market rallies, (b) result in greater losses compared to the market trend;'
            },
            {
              'it-IT':
                'l’implementazione di una strategia di trading automatizzata può comportare costi di transazione superiori rispetto ad una attività di trading manuale diretta dell’Utente, in ragione del numero di operazioni che il Bot potrebbe eseguire anche al fine di perseguire l’obbiettivo di limitare le perdite in momento di mercato ribassisti;',
              'en-EN':
                'implementing an automated trading strategy can incur higher transaction costs compared to direct manual trading by the User, due to the number of transactions that the Bot might execute, also in order to pursue the goal of limiting losses during bearish market conditions;'
            },
            {
              'it-IT':
                'per quanto la strategia di investimento del Bot tenga in considerazione i costi di transazione derivanti dall’attività di acquisto e vendita di crypto-attività, l’Utente accetta che i risultati della strategia potrebbero risentire negativamente o essere neutralizzati in tutto o in parte dai costi di transazione stessi;',
              'en-EN':
                'even though the Bot’s investment strategy takes into account transaction costs arising from buying and selling cryptocurrencies, the User accepts that the strategy’s results may be negatively affected or neutralized in whole or in part by those transaction costs;'
            },
            {
              'it-IT':
                'i costi di transazione dipendono esclusivamente dall’Exchange su cui il Servizio si appoggia e possono subire variazioni nel corso del tempo nonché variare in ragione della tipologia di crypto-attività negoziata e del volume delle transazioni effettuate indipendentemente dalla volontà di Hodlie; i costi di transazione vengono addebitati dall’Exchange trattenendo una determinata percentuale della crypto-attività oggetto di negoziazione o depositata sul conto aperto presso l’Exchange; in determinati casi l’Utente, tramite impostazione sul suo account presso l’Exchange, può scegliere di pagare le fee di transazione mediante specifici token indicati dall’Exchange stesso (come nel caso del token BNB per Binance) anche al fine di ottenere una riduzione delle commissioni;',
              'en-EN':
                'transaction costs depend solely on the Exchange on which the Service is based and can change over time and vary depending on the type of traded cryptocurrencies and the volume of transactions conducted, independent of Hodlie’s intentions; transaction costs are charged by the Exchange by deducting a certain percentage from the cryptocurrency subject to trading or deposited in the account opened with the Exchange; in certain cases, the User, through settings on their Exchange account, can choose to pay transaction fees using specific tokens indicated by the Exchange itself (as in the case of BNB token for Binance) to obtain a fee reduction;'
            },
            {
              'it-IT':
                'al momento dell’attivazione del Servizio, Hodlie interrogherà l’account dell’Utente presso l’Exchange al fine di verificare quale impostazione è attivata circa la modalità e crypto-attività di riferimento per pagamento delle commissioni di transazione (ad esempio se BNB o altra crypto-attività); l’Utente autorizza Hodlie ad approvvigionarsi per suo conto delle crypto-attività prescelte per il pagamento delle commissioni di transazione nella misura minima indispensabile per coprire detti costi e consentire l’efficace svolgimento dell’attività di trading; le crypto-attività destinati a coprire i costi di transazione non verranno movimentati da Hodlie ma costituiranno una side pocket esclusivamente destinata a tale fine;',
              'en-EN':
                'upon activating the Service, Hodlie will query the User’s account at the Exchange to verify which setting is enabled regarding the mode and cryptocurrency used for paying transaction fees (for example, whether BNB or another cryptocurrency); the User authorizes Hodlie to procure on their behalf the chosen cryptocurrencies to cover transaction fees to the minimum extent necessary to cover those costs and allow the effective execution of trading; the cryptocurrencies allocated to cover transaction costs will not be moved by Hodlie but will constitute a side pocket exclusively intended for that purpose;'
            },
            {
              'it-IT':
                'l’Utente, al momento dell’attivazione del o dei Bot, visualizzerà la modalità di pagamento delle commissioni di transazione attualmente selezionate sul suo account presso l’Exchange e avrà la possibilità di confermarla o modificarla; in caso di modifica prima o durante la prestazione del Servizio, l’Utente autorizza sin da ora Hodlie a intervenire sull’account presso l’Exchange al fine di rendere le impostazioni coerenti con la scelta effettuata (ad esempio, se l’Utente decidesse di pagare le commissioni in BNB ma non avesse l’opzione selezionata presso l’Exchange, Hodlie sarà autorizzata a modificare l’impostazione presso l’Exchange per soddisfare la richiesta dell’Utente);',
              'en-EN':
                'at the time of activating one or more Bots, the User will view the current selected method of paying transaction fees on their Exchange account and will have the option to confirm or modify it; in case of modification before or during the provision of the Service, the User authorizes Hodlie to intervene on their Exchange account to make the settings consistent with the choice made (for example, if the User chooses to pay fees in BNB but hasn’t selected that option on the Exchange, Hodlie will be authorized to modify the setting on the Exchange to fulfill the User’s request);'
            },
            {
              'it-IT':
                'nell’ipotesi in cui l’Utente dovesse modificare le impostazioni presso l’Exchange selezionando una crypto-attività per il pagamento delle commissioni diversa da quella indicata a Hodlie in fase di prestazione del Servizio, tutti i Bot attivati dall’Utente si imposteranno in via automatica per il pagamento delle commissioni di transazione nella nuova crypto-attività scelta dall’Utente presso l’Exchange; in tal caso, l’Utente autorizza sin da ora Hodlie a liquidare la side pocket per il pagamento delle fee di transazione e riconvertirla nella nuova crypto-attività di riferimento;',
              'en-EN':
                'if the User were to modify settings on the Exchange by selecting a different cryptocurrency for paying transaction fees than the one indicated to Hodlie during the provision of the Service, all the Bots activated by the User will automatically set themselves to pay transaction fees in the new cryptocurrency chosen by the User on the Exchange; in this case, the User authorizes Hodlie in advance to liquidate the side pocket for paying transaction fees and convert it into the new reference cryptocurrency;'
            },
            {
              'it-IT':
                'l’Utente accetta che le crypto-attività destinate a coprire i costi di transazione possono essere soggette a variazione nel valore di mercato e potrebbero influire negativamente sui risultati complessivi della strategia di trading del Bot;',
              'en-EN':
                'the User accepts that the cryptocurrencies allocated to cover transaction costs may be subject to changes in market value and could negatively impact the overall results of the Bot’s trading strategy;'
            },
            {
              'it-IT':
                'Hodlie, al di fuori del costo dell’Abbonamento, non percepisce alcuna ulteriore remunerazione, in particolare derivante dall’attività di trading automatizzata sull’Exchange né alcuna retrocessione delle fees da parte dell’Exchange, e dunque non ha alcun interesse in conflitto con quello dell’Utente derivante dall’esecuzione di un determinato numero di operazioni; nel caso in cui, per qualsiasi motivo, l’Exchange dovesse riconoscere a Hodlie una retrocessione di parte delle commissioni di negoziazione pagate dall’Utente, Hodlie restituirà senza indugio all’Utente tali importi;',
              'en-EN':
                'Hodlie, apart from the Subscription fee, does not receive any additional compensation, particularly from the automated trading activity on the Exchange, nor any fee rebates from the Exchange, and therefore has no conflicting interest with that of the User arising from the execution of a certain number of transactions; in the event that, for any reason, the Exchange were to provide Hodlie with a rebate of a portion of the trading fees paid by the User, Hodlie will promptly return such amounts to the User;'
            },
            {
              'it-IT':
                'l’Utente ha, in qualsiasi momento, la facoltà di sospendere (pulsante “Metti in pausa”) o interrompere (pulsante “Elimina il bot”) l’attività del Bot prescelto; la sospensione o l’interruzione dell’attività del Bot potrebbe compromettere l’efficacia della strategia di trading in esecuzione e generare perdite o mancati profitti di cui Hodlie non potrà essere ritenuta in alcun modo responsabile; in caso di sospensione o interruzione del o dei Bot, l’Utente avrà la possibilità d scegliere se mantenere invariata la composizione del portafoglio al momento della sospensione o convertire tutto il portafoglio in crypto-attività aventi meccanismi di stabilizzazione del valore (i.e. USDT);',
              'en-EN':
                'the User has the option at any time to pause (button "Pause") or stop (button "Delete the bot") the activity of the chosen Bot; suspending or stopping the Bot’s activity may compromise the effectiveness of the ongoing trading strategy and result in losses or missed profits for which Hodlie cannot be held responsible in any way; in case of suspension or interruption of one or more Bots, the User will have the option to choose whether to maintain the portfolio composition at the time of suspension or convert the entire portfolio into cryptocurrencies with value stabilization mechanisms (i.e., USDT);'
            },
            {
              'en-EN':
                "the User has, at any time, the right to request a change in the risk line of the Bot initially chosen; this operation, which in the future may be subject to Hodlie's assessment of the compatibility of the new risk line with the User's profile, may entail the need to rearrange the portfolio and, consequently, may not be of immediate execution and may require the execution of transactions aimed at rebalancing the portfolio in view of the new chosen strategy;",
              'it-IT':
                'l’Utente ha, in qualsiasi momento, la facoltà di chiedere la modifica della linea di rischio del Bot inizialmente scelto; tale operazione, che in futuro potrà essere soggetta alla valutazione da parte di Hodlie della compatibilità della nuova linea di rischio con il profilo dell’Utente, potrebbe comportare la necessità di riassetto del portafoglio e, conseguentemente, non essere di immediata esecuzione e richiedere l’effettuazione di operazioni volte a ribilanciare il portafoglio in vista della nuova strategia prescelta;'
            },
            {
              'en-EN':
                'the User remains in the right, at any time, to dispose (sell, pledge in any capacity, transfer) of the crypto-assets subject to the Service by operating directly on the supporting Exchange at which he/she has the account; in such cases, the User acknowledges and accepts that such direct operation could jeopardize the effective execution of the trading strategy of the Bot, therefore Hodlie may automatically suspend the execution of one or more Bots; the User, in the event of suspension by Hodlie, will be promptly notified and may reactivate the Bot at any time depending on the new amount of crypto-assets available at the Exchange, subject to the limitations on the minimum amounts of crypto-assets manageable by the Bot(s) pursuant to Section 8. 2.1.; in the event of suspension of one or more Bots due to insufficiency or inconsistency of the crypto-assets entrusted for management, the composition of the portfolio under management shall remain unchanged; however, the User shall have the option to discontinue (button "Delete Bot") the Bot(s) according to the mechanism set forth in Section 8.2.12;',
              'it-IT':
                'l’Utente rimane nella facoltà, in qualsiasi momento, di disporre (vendere, impegnare a qualsiasi titolo, trasferire) delle crypto-attività oggetto del Servizio operando direttamente sull’Exchange di appoggio presso cui ha il conto; in questi casi, l’Utente prende atto e accetta che tale operatività diretta potrebbe compromettere l’efficace esecuzione della strategia di trading del Bot, pertanto Hodlie potrà sospendere automaticamente l’esecuzione di uno o più Bot; l’Utente, in caso di sospensione da parte di Hodlie, verrà prontamente informato e potrà riattivare il Bot in qualsiasi momento in funzione del nuovo quantitativo di crypto-attività disponibile presso l’Exchange, salve le limitazioni sui quantitativi minimi di crypto-attività gestibili dal o dai Bot ai sensi del punto 8.2.1.; in caso di sospensione di uno o più Bot per insufficienza o inconsistenza delle crypto-attività affidate in gestione, la composizione del portafoglio in gestione rimarrà invariata; l’Utente avrà comunque la possibilità di interrompere (pulsante “Elimina il bot”) il o i Bot secondo il meccanismo di cui al punto 8.2.12;'
            },
            {
              'en-EN':
                'Hodlie provides no guarantee or promise of financial return from the provision of the Service that does not exclude the risk of partial or total loss of capital. In particular, the User is aware that investment in crypto-assets has high risk profiles and accepts the risk of loss of capital. None of the information on the Site and charts on the performance of the Bots can be considered as promises of future performance or promises of returns or containment of losses. The information and graphs on the Site are solely a representation of historical performance data that may not replicate in the future and in no way constitute a solicitation to increase principal entrusted or to choose a more aggressive line of risk.',
              'it-IT':
                'Hodlie non fornisce alcuna garanzia o promessa di rendimento economico derivante dalla prestazione del Servizio che non esclude il rischio di perdita parziale o totale del capitale. In particolare l’Utente è consapevole che l’investimento in crypto-attività presenta elevati profili di rischiosità e accetta il rischio di perdita del capitale. Nessuna delle informazioni presenti sul Sito e grafici sulle performance dei Bot possono essere considerate come promesse di performance future o promesse di rendimenti o contenimento delle perdite. Le informazioni e grafici presenti sul Sito sono esclusivamente la rappresentazione di dati storici di performance che potrebbero non replicarsi nel futuro e che non costituiscono in alcun modo sollecitazione all’incremento del capitale affidato o alla scelta di una linea di rischio più aggressiva.'
            }
          ]
        },
        {
          'en-EN':
            'The User expressly acknowledges and agrees that upon satisfaction of the above conditions, Hodlie via API Key:',
          'it-IT':
            'L’Utente riconosce e accetta espressamente che, soddisfatte le condizioni di cui sopra, Hodlie tramite API Key:',
          subtexts: [
            {
              'en-EN':
                'will directly place, via the Bot, buy and sell orders on the crypto-asset pair(s) chosen by the User and which the User must necessarily already own on the Exchange; the crypto-asset pairs available for trading activity are indicated on the Site;',
              'it-IT':
                'effettuerà direttamente, tramite il Bot, ordini di acquisto e vendita sulla coppia o sulle coppie di crypto-attività scelte dall’Utente e che l’Utente deve necessariamente già possedere sull’Exchange; le coppie di crypto-attività disponibili per l’attività di trading sono indicate sul Sito;'
            },
            {
              'en-EN':
                'will read the history of the orders placed and the details of a User’s portfolio, i.e. the so-called "Tradable Quantity";',
              'it-IT':
                'leggerà lo storico degli ordini effettuati e il dettaglio del portafoglio di un Utente, ovvero la cosiddetta “Tradable Quantity”;'
            },
            {
              'en-EN':
                'may not withdraw or deposit sums of money and/or crypto-assets on behalf of the User;',
              'it-IT':
                'non potrà ritirare né depositare somme di denaro e/o crypto-attività per conto dell’Utente;'
            },
            {
              'en-EN':
                'may not prevent the User from either withdrawing or depositing sums of money and/or crypto-assets, as well as block the User’s operations in any way;',
              'it-IT':
                'non potrà impedire all’Utente né di ritirare né di depositare somme di denaro e/o cripto-attività, così come bloccare in alcun modo l’operatività dell’Utente;'
            }
          ]
        }
      ]
    },
    {
      link: 'representations',
      name: {
        'en-EN': 'User representations and warranties',
        'it-IT': 'Dichiarazioni e garanzie dell’Utente'
      },
      texts: [
        {
          'en-EN': 'The User declares and warrants:',
          'it-IT': 'L’Utente dichiara e garantisce:',
          subtexts: [
            {
              'en-EN': 'to be able to validly enter into the Agreement;',
              'it-IT': 'di poter legittimamente stipulare il Contratto;'
            },
            {
              'en-EN': 'to be of legal age;',
              'it-IT': 'di essere maggiorenne;'
            },
            {
              'en-EN':
                'that the personal data and other information provided to Hodlie during registration on the Site or when purchasing the Service are true, accurate, and up-to-date as required in the <b><a href="/termsofuse" target="_blank" rel="noreferrer"><u>Terms of Use</u></a></b> of the Site;',
              'it-IT':
                'che i dati personali e le altre informazioni comunicate a Hodlie in fase di registrazione sul Sito o di acquisto del Servizio sono veritieri, corretti e aggiornati come previsto nelle <b><a href="/termsofuse" target="_blank" rel="noreferrer"><u>Condizioni di utilizzo</u></a></b> del Sito;'
            },
            {
              'en-EN':
                'to use the Site in compliance with any applicable law or regulation, refraining from any form of direct and/or indirect use of the Site that is contrary to the law, the <b><a href="/termsofuse" target="_blank" rel="noreferrer"><u>Terms of Use</u></a></b> of the Site, these Terms and Conditions, or harmful to third-party rights;',
              'it-IT':
                'che utilizzerà il Sito nel rispetto di qualsiasi disposizione di legge o regolamento applicabile, astenendosi da ogni forma di utilizzo diretto e/o indiretto del Sito che sia in contrasto con la legge, le <b><a href="/termsofuse" target="_blank" rel="noreferrer"><u>Condizioni di utilizzo</u></a></b> del Sito, i presenti Termini e Condizioni o lesivo di diritti di terzi;'
            },
            {
              'en-EN':
                'to have sufficient cryptocurrency in your Exchange account to place buy and sell orders through the Bot;',
              'it-IT':
                'che dispone sul proprio conto Exchange della quantità di criptovaluta necessaria per effettuare gli ordini di acquisto e vendita tramite Bot;'
            },
            {
              'en-EN':
                'that the costs of internet connection are those related to the operator used for connectivity and are not included in the Subscription Fee, and therefore will be charged to the User;',
              'it-IT':
                'che i costi di connessione alla rete internet sono quelli legati all’operatore utilizzato per la connettività e non sono inclusi nella Tariffa di Abbonamento e saranno pertanto a carico dell’Utente;'
            },
            {
              'en-EN':
                'to have a device capable of supporting the API Key and integrating it correctly with the Site, downloading from the internet and correctly installing any necessary software applications (Apps) to install the API Key; and',
              'it-IT':
                'di essersi dotato di un dispositivo in grado di supportare l’API Key e integrarla correttamente al Sito, scaricando da internet e installando correttamente tutte le applicazioni software eventualmente necessarie (App) per installare l’API Key; e'
            },
            {
              'en-EN':
                'to have access to the internet and connectivity service (e.g. ADSL, WI-FI, 3G/4G/5G) in order to access the Site.',
              'it-IT':
                'di disporre di un accesso a internet e del servizio di connettività (es. ADSL, WI-FI, 3G/4G/5G) per poter accedere al Sito.'
            }
          ]
        },
        {
          'en-EN':
            "User agrees to indemnify and hold Hodlie harmless against any and all liabilities, actions, costs, expenses, and claims arising out of User's breach of the representations and warranties set forth in Clause 9.1 above, subject in each case to Hodlie's ability to terminate the Agreement with immediate effect pursuant to Article 1456 of the Civil Code in the event of User's breach of the foregoing representations and warranties.",
          'it-IT':
            'L’Utente si impegna a tenere indenne e manlevata Hodlie a fronte di ogni responsabilità, azione, costo, spesa e pretesa che possa derivare dalla violazione da parte dell’Utente delle dichiarazioni e garanzie di cui alla Clausola 9.1. che precede, fatta salva in ogni caso la possibilità per Hodlie di risolvere il Contratto con effetto immediato a norma dell’articolo 1456 del Codice Civile in caso di violazione da parte dell’Utente delle suddette dichiarazioni e garanzie.'
        }
      ]
    },
    {
      link: 'conformity',
      name: {
        'en-EN': 'Terms of Service Provision and Legal Warranty of Conformity',
        'it-IT':
          'Condizioni di fornitura del Servizio e garanzia legale di conformità'
      },
      texts: [
        {
          'it-IT':
            'Il Servizio sarà accessibile all’Utente solo a seguito di corretta identificazione da parte di Hodlie ai sensi delle vigenti disposizioni in materia di contrasto all’attività di riciclaggio. Ad esito della corretta identificazione, secondo le procedure presenti sul Sito, il Servizio sarà attivato e l’Utente potrà scegliere un Bot, che eseguirà automaticamente gli ordini di acquisto e vendita sull’Exchange prescelto secondo la linea di rischio associata.',
          'en-EN':
            'The Service will be accessible to the User only following proper identification by Hodlie in accordance with current provisions concerning the contrast to money laundering activities. Upon successful identification, following the procedures on the Website, the Service will be activated, and the User will be able to choose a Bot, which will automatically execute purchase and sale orders on the chosen Exchange according to the associated risk level.'
        },
        {
          'it-IT':
            'Il Servizio si qualifica come un “servizio digitale” ai sensi dell’articolo 135-octies del Codice del Consumo. Pertanto, per la durata dell’Abbonamento, Hodlie garantisce all’Utente la conformità del Servizio, incluso il Bot, alle caratteristiche indicate nelle specifiche dello stesso sul Sito e nei presenti Termini e Condizioni.',
          'en-EN':
            'The Service qualifies as a “digital service” under article 135-octies of the Consumer Code. Therefore, for the duration of the Subscription, Hodlie guarantees to the User the conformity of the Service, including the Bot, with the characteristics indicated in its specifications on the Website and in these Terms and Conditions.'
        },
        {
          'it-IT':
            'L’Utente decade dai propri diritti relativi alla presente garanzia legale di conformità nel termine di ventisei mesi dal giorno della scadenza dell’ultimo Abbonamento sottoscritto, contattando Hodlie tramite e-mail all’indirizzo indicato alla Clausola 17.',
          'en-EN':
            "The User's rights related to this legal warranty of conformity expire within twenty-six months from the day of expiry of the last subscribed Subscription, by contacting Hodlie via email at the address indicated in Clause 17."
        },
        {
          'it-IT':
            'In caso di difetto di conformità del Servizio, l’Utente può chiedere, a sua discrezione, il ripristino della conformità, una congrua riduzione del prezzo o la risoluzione del Contratto.',
          'en-EN':
            'In case of non-conformity of the Service, the User can request, at their discretion, restoration of conformity, a reasonable reduction of the price, or termination of the Contract.'
        },
        {
          'it-IT':
            'Se richiesta dall’Utente, la riduzione del prezzo dovrà essere proporzionale alla diminuzione di valore del Servizio fornito all’Utente rispetto al valore che avrebbe se fosse stato conforme e verrà calcolata in base al periodo di tempo in cui il Servizio, incluso il Bot, non è stato conforme.',
          'en-EN':
            'If requested by the User, the price reduction must be proportional to the decrease in value of the Service provided to the User compared to the value it would have had if it had been compliant, and will be calculated based on the period of time in which the Service, including the Bot, was not in compliance.'
        },
        {
          'it-IT':
            'L’Utente non potrà chiedere la risoluzione del contratto se Hodlie proverà che il difetto lamentato dall’Utente è solo di lieve entità.',
          'en-EN':
            'The User cannot request contract termination if Hodlie proves that the defect complained of by the User is of minor entity.'
        },
        {
          'it-IT':
            'L’Utente prende atto e accetta che non saranno coperti dalla suddetta garanzia legale eventuali vizi o danneggiamenti determinati da fatti accidentali o da responsabilità dell’Utente – quali, a titolo esemplificativo e non esaustivo, limitata o assenza di connessione alla rete internet che impedisce l’accesso al Sito, oppure l’errata creazione dell’API Key o il mancato collegamento con l’Exchange – o dall’uso del Servizio non conforme alla relativa destinazione d’uso.',
          'en-EN':
            "The User acknowledges and accepts that defects or damages caused by accidental events or User's responsibilities – such as, without limitation, limited or absence of internet connection preventing access to the Site, or incorrect creation of the API Key or failure to connect to the Exchange – or the use of the Service not in accordance with its intended purpose will not be covered by the aforementioned legal guarantee."
        },
        {
          'it-IT':
            'In caso di eventi di forza maggiore, caso fortuito e fuori del controllo di Hodlie, quali ad esempio malfunzionamenti della piattaforma di scambio dell’Exchange, interruzioni dell’operatività sull’Exchange per ragioni tecniche o giuridiche o amministrative (come ad esempio chiusura delle attività in Italia, revoche delle licenze, oscuramenti da parte delle autorità di vigilanza, chiusura del conto dell’Utente), scioperi, alluvioni, terremoti, epidemie o provvedimenti da parte delle autorità nazionali o locali, la fornitura del Servizio potrebbe subire rallentamenti e/o interruzioni. In tal caso, Hodlie ne darà adeguata comunicazione via e-mail all’Utente. Hodlie non potrà essere ritenuta responsabile per gli eventuali disservizi in relazione alla fornitura del Servizio derivanti dagli eventi di cui alla presente Clausola.',
          'en-EN':
            "In case of force majeure, unforeseeable circumstances, and events beyond Hodlie's control, such as malfunctions of the Exchange's trading platform, interruptions in the Exchange's operations for technical, legal, or administrative reasons (such as closure of activities in Italy, revocation of licenses, authority-imposed shutdowns, closure of the User's account), strikes, floods, earthquakes, epidemics, or measures taken by national or local authorities, the provision of the Service may experience slowdowns and/or interruptions. In such cases, Hodlie will provide appropriate communication via email to the User. Hodlie cannot be held responsible for any disruptions related to the provision of the Service resulting from events described in this Clause."
        },
        {
          'it-IT':
            'In caso di malfunzionamenti o disservizi esterni che, pur non pregiudicando o impedendo l’esecuzione del Servizio, possano impattare negativamente sulla performance del o dei Bot o di bug riscontrati negli algoritmi del o dei Bot, per massima tutela dell’Utente Hodlie si riserva il diritto di liquidare in tutto o in parte il portafoglio dell’Utente convogliando la liquidità su crypto-attività aventi meccanismi di stabilizzazione del prezzo (i.e. USDT).',
          'en-EN':
            "In case of malfunctions or external issues that, while not preventing the execution of the Service, may negatively impact the performance of the Bot(s) or bugs found in the Bot(s)' algorithms, for the maximum protection of the User, Hodlie reserves the right to liquidate all or part of the User's portfolio, channeling the liquidity into crypto-assets with price stabilization mechanisms (i.e., USDT)."
        }
      ]
    },
    {
      link: 'updates',
      name: {
        'en-EN': 'Service updates and integration',
        'it-IT': 'Aggiornamenti e integrazione del Servizio'
      },
      texts: [
        {
          'en-EN':
            "Unless otherwise agreed between Hodlie and the User, the Service, and the related Bot, are provided in the most recent version available at the time of the conclusion of the Agreement. Hodlie will keep the User informed of available updates, including security updates, necessary for the purpose of maintaining compliance of the Service, and to provide them to the User, during the Subscription period by communication via e-mail or pop-up within the User's account.",
          'it-IT':
            'Salvo diverso accordo tra Hodlie e l’Utente, il Servizio, e il relativo Bot, sono forniti nella versione più recente disponibile al momento della conclusione del Contratto. Hodlie terrà informato l’Utente sugli aggiornamenti disponibili, anche di sicurezza, necessari al fine di mantenere la conformità del Servizio, e a fornirglieli, durante il periodo di Abbonamento mediante comunicazione tramite e-mail o pop-up all’interno dell’account dell’Utente.'
        },
        {
          'en-EN':
            'If User fails to install within a reasonable time the updates provided by Hodlie pursuant to Clause 11.1, Hodlie shall not be liable for any lack of conformity arising solely from the lack of the relevant update, provided that:',
          'it-IT':
            "Se l’Utente non installa entro un congruo termine gli aggiornamenti forniti da Hodlie ai sensi della Clausola 11.1, Hodlie non sarà responsabile per qualsiasi difetto di conformità derivante unicamente dalla mancanza dell'aggiornamento pertinente, a condizione che:",
          subtexts: [
            {
              'en-EN':
                "Hodlie has informed the User of the availability of the update and the consequences of the User's failure to install the update; and",
              'it-IT':
                'Hodlie abbia informato l’Utente della disponibilità dell’aggiornamento e delle conseguenze della mancata installazione dello stesso da parte dell’Utente; e'
            },
            {
              'en-EN':
                "User's failure to install or incorrect installation of the update is not due to deficiencies in the installation instructions provided by Hodlie.",
              'it-IT':
                "la mancata installazione o l'installazione errata dell'aggiornamento da parte dell’Utente non è dovuta a carenze delle istruzioni di installazione fornite da Hodlie."
            }
          ]
        },
        {
          'en-EN':
            "Any lack of conformity that results from improper integration of the Service into the User's digital environment may be considered a lack of conformity of the Service only if:",
          'it-IT':
            'L’eventuale difetto di conformità che deriva da un’errata integrazione del Servizio nell’ambiente digitale dell’Utente potrà essere considerato difetto di conformità del Servizio solamente se:',
          subtexts: [
            {
              'en-EN':
                'the Service has been integrated by Hodlie or under his responsibility; or',
              'it-IT':
                'il Servizio è stato integrato da Hodlie o sotto la sua responsabilità; oppure'
            },
            {
              'en-EN':
                'the Service required an integration by Hodlie, and the erroneous integration was due to a deficiency in the integration instructions provided by Hodlie.',
              'it-IT':
                "il Servizio richiedeva un’integrazione da parte di Hodlie e l'errata integrazione è dovuta a una carenza delle istruzioni di integrazione fornite da Hodlie."
            }
          ]
        }
      ]
    },
    {
      link: 'termination',
      name: {
        'en-EN': 'Termination of the contract',
        'it-IT': 'Risoluzione del contratto'
      },
      texts: [
        {
          'it-IT':
            'Se Hodlie non fornisce il Servizio conformemente alla Clausola 10.1, l’Utente potrà invitare Hodlie a fornire il Servizio. Se Hodlie continua a non fornire il Servizio entro un termine congruo oppure entro un ulteriore termine espressamente concordato fra Hodlie e l’Utente, l’Utente ha il diritto di risolvere il Contratto.',
          'en-EN':
            'If Hodlie fails to provide the Service in accordance with Clause 10.1, the User may invite Hodlie to provide the Service. If Hodlie continues to not provide the Service within a reasonable period or within an additional period expressly agreed upon between Hodlie and the User, the User has the right to terminate the Contract.'
        },
        {
          'it-IT': 'L’Utente potrà risolvere immediatamente il Contratto se:',
          'en-EN': 'The User may immediately terminate the Contract if:',

          subtexts: [
            {
              'it-IT':
                'Hodlie ha dichiarato, o risulta altrettanto chiaramente dalle circostanze, che non fornirà il Servizio; oppure',
              'en-EN':
                'Hodlie has stated, or it is equally clear from the circumstances, that it will not provide the Service; or'
            },
            {
              'it-IT':
                'Hodlie e l’Utente hanno convenuto, o risulta evidente dal Contratto o dalle circostanze che hanno portato alla sua conclusione, che un tempo specifico per la fornitura è essenziale per l’Utente e Hodlie omette di fornire il Servizio entro o in tale momento .',
              'en-EN':
                'Hodlie and the User have agreed, or it is evident from the Contract or the circumstances leading to its conclusion, that a specific time for delivery is essential for the User, and Hodlie fails to provide the Service within or by that time.'
            }
          ]
        },
        {
          'it-IT':
            'In caso di difetto di conformità grave del Servizio, l’Utente potrà risolvere il Contratto mediante una dichiarazione scritta a Hodlie, inviandola ai contatti riportati nella Clausola 17, in cui manifesta la espressamente volontà di risolvere il Contratto.',
          'en-EN':
            'In case of serious non-compliance of the Service, the User can terminate the Contract by means of a written declaration to Hodlie, sending it to the contacts provided in Clause 17, in which they expressly express the will to terminate the Contract.'
        },
        {
          'it-IT':
            'Hodlie non effettuerà alcun rimborso all’Utente delle Tariffe di Abbonamento da quest’ultimo corrisposte, fatto salvo per la parte dell’importo pagato per il periodo in cui il Servizio non è stato conforme e per la parte del prezzo pagato in anticipo dall’Utente e relativa alla durata dell’Abbonamento rimanente.',
          'en-EN':
            'Hodlie will not refund the User for the Subscription Rates paid by the latter, except for the portion of the amount paid for the period in which the Service was not compliant, and for the portion of the price paid in advance by the User and related to the remaining Subscription period.'
        },
        {
          'it-IT':
            'L’Utente riconosce espressamente e accetta che, a seguito alla risoluzione del Contratto, non potrà più utilizzare il Servizio. Pertanto, Hodlie potrà impedire qualsiasi ulteriore utilizzo del Servizio da parte dell’Utente, in particolare, rendendogli il Servizio inaccessibile o disattivando il suo account.',
          'en-EN':
            'The User expressly acknowledges and agrees that, following the termination of the Contract, they will no longer be able to use the Service. Therefore, Hodlie may prevent any further use of the Service by the User, in particular, by making the Service inaccessible to them or by deactivating their account.'
        },
        {
          'it-IT':
            'Hodlie potrà risolvere il Contratto e cancellare l’account dell’Utente con effetto immediato, dandone avviso all’Utente, se Hodlie ritiene che l’Utente utilizzi il Servizio (a) in violazione materiale delle presenti Condizioni Generali o di qualsiasi legge applicabile o (b) in maniera fraudolenta. In tal caso, nessun rimborso sarà dovuto.',
          'en-EN':
            "Hodlie may terminate the Contract and delete the User's account with immediate effect, notifying the User, if Hodlie believes that the User uses the Service (a) in material violation of these General Conditions or any applicable law, or (b) in a fraudulent manner. In such case, no refund will be due."
        }
      ]
    },
    {
      link: 'withdrawal',
      name: {
        'en-EN': 'Withdrawal Right',
        'it-IT': 'Diritto di recesso'
      },
      texts: [
        {
          'it-IT':
            'Il Servizio ha ad oggetto un contenuto di natura digitale. Pertanto, l’Utente riconosce espressamente che non potrà esercitare il diritto di recesso previsto dagli artt. 52 e ss. del Codice del Consumo in quanto la fruizione del Servizio rientra nell’ipotesi di esclusione di tale diritto contemplata dall’art. 59, comma 1, lett. o) del Codice del Consumo, fatto salvo quanto previsto alla Clausola 5.7., nonché la possibilità per l’Utente di disattivare il rinnovo automatico dell’Abbonamento nei termini di cui alle Clausole 5.5. sopra e 13.3. sotto.',
          'en-EN':
            'The Service concerns digital content. Therefore, the User expressly acknowledges that they cannot exercise the right of withdrawal provided for in Articles 52 et seq. of the Consumer Code, as the use of the Service falls within the scope of exclusion of this right as contemplated in Article 59, paragraph 1, letter o) of the Consumer Code, without prejudice to what is provided in Clause 5.7., as well as the possibility for the User to deactivate the automatic renewal of the Subscription in accordance with Clauses 5.5. above and 13.3. below.'
        },
        {
          'it-IT':
            'Fermo restando quanto previsto alle Clausole 5.5., 5.7. e 13.3., con l’accettazione dei presenti Termini e Condizioni e l’utilizzo del Servizio, l’Utente fornisce il suo previo consenso espresso a usufruire del Servizio durante il periodo di diritto di recesso – che decorre per 14 (quattordici) giorni dal momento in cui il Servizio è accessibile all’Utente – e riconosce espressamente di perdere così il proprio diritto di recesso.',
          'en-EN':
            'Without prejudice to what is provided in Clauses 5.5., 5.7., and 13.3., by accepting these Terms and Conditions and using the Service, the User gives their prior express consent to use the Service during the withdrawal period – which lasts for 14 (fourteen) days from the moment when the Service is accessible to the User – and expressly acknowledges forfeiting their right of withdrawal.'
        },
        {
          'it-IT':
            'Tuttavia, in conformità a quanto previsto alle Clausole 5.5. e 5.7., l’Utente potrà sempre impedire il rinnovo automatico dell’Abbonamento e recedere dal Contratto inviando una comunicazione a Hodlie, ai contatti riportati nella Clausola 17, con un preavviso di 15 (quindici) giorni rispetto alla data di rinnovo dell’Abbonamento.',
          'en-EN':
            'However, in accordance with what is provided in Clauses 5.5. and 5.7., the User can always prevent the automatic renewal of the Subscription and terminate the Contract by sending a communication to Hodlie, using the contacts provided in Clause 17, with a notice of 15 (fifteen) days before the Subscription renewal date.'
        }
      ]
    },
    {
      link: 'assignment',
      name: {
        'en-EN': 'Assignment of Contract',
        'it-IT': 'Cessione del Contratto'
      },
      texts: [
        {
          'it-IT':
            'Hodlie è autorizzata a cedere a terzi l’Abbonamento, senza necessità di ulteriore accettazione da parte dell’Utente. L’Utente non potrà, salva preventiva autorizzazione scritta di Hodlie, cedere il proprio Abbonamento o i diritti e/o le obbligazioni dallo stesso derivanti a terzi.',
          'en-EN':
            'Hodlie is authorized to assign the Subscription to third parties, without requiring further acceptance from the User. The User cannot, except with prior written authorization from Hodlie, assign their Subscription or the rights and/or obligations arising from it to third parties.'
        }
      ]
    },
    {
      link: 'processing',
      name: {
        'en-EN': 'Processing of personal data',
        'it-IT': 'Trattamento dei dati personali'
      },
      texts: [
        {
          'it-IT':
            "Hodlie tratta i dati personali dell’Utente per le finalità e con le modalità specificate nella propria <b>Informativa sul trattamento dei dati personali</b> disponibile sul <a href='" +
            privacyPolicyLink +
            "' target='_blank' rel='noreferrer'><u>Sito</u></a>.",
          'en-EN':
            "Hodlie processes User's personal data for the purposes and in the manner specified in its <b>Privacy Policy</b> available on the <a href='" +
            privacyPolicyLink +
            "' target='_blank' rel='noreferrer'><u>Site</u></a>."
        }
      ]
    },
    {
      link: 'law',
      name: {
        'en-EN': 'Applicable law and place of jurisdiction',
        'it-IT': 'Legge applicabile e foro competente'
      },
      texts: [
        {
          'en-EN':
            'These Terms and Conditions shall be governed entirely by the laws of Italy. Any dispute arising in connection with the validity, interpretation, performance and termination of these Terms and Conditions or each Contract shall be within the exclusive jurisdiction,',
          'it-IT':
            'I presenti Termini e Condizioni sono interamente disciplinati dalla legge italiana. Qualsiasi controversia che dovesse sorgere in relazione alla validità, interpretazione, esecuzione e risoluzione delle presenti Termini e Condizioni o di ciascun Contratto sarà di esclusiva competenza,',
          subtexts: [
            {
              'it-IT':
                'nel caso in cui l’Utente sia un consumatore, del tribunale del luogo di residenza del consumatore, qualora sia situato in Italia; mentre',
              'en-EN':
                'in case the User is a consumer, of the court of the place of residence of the consumer, if it is located in Italy; while'
            },
            {
              'it-IT':
                'negli scenari diversi da quelli cui alla precedente Clausola 16.1.1., del Tribunale di Milano.',
              'en-EN':
                'in scenarios other than those referred to in Clause 16.1.1 above, of the Court of Milan.'
            }
          ]
        },
        {
          'en-EN':
            'Without prejudice to the User’s right to take action before the competent court provided for in Clause 16.1. above, in accordance with Article 14 of Regulation 524/2013/EC, the User also has the option of having recourse to the platform established by the European Commission for online dispute resolution ("<b>ODR Platform</b>"), through the assistance of an impartial body. The ODR Platform can be accessed at the following <a href="https://ec.europa.eu/consumers/odr/main/?event=main.home.show" target="_blank" rel="noreferrer"><u>link</u></a>.',
          'it-IT':
            'Fatto salvo il diritto dell’Utente di agire davanti al tribunale competente previsto alla Clausola 16.1. sopra, in conformità con l’articolo 14 del Regolamento 524/2013/CE, l’Utente ha altresì la possibilità di ricorrere alla piattaforma istituita dalla Commissione Europea per la risoluzione delle controversie online ("<b>Piattaforma ODR</b>"), tramite l’assistenza di un organo imparziale. La Piattaforma ODR è accessibile al seguente <a href="https://ec.europa.eu/consumers/odr/main/?event=main.home.show" target="_blank" rel="noreferrer"><u>link</u></a>.'
        }
      ]
    },
    {
      link: 'contacts',
      name: {
        'en-EN': 'Contacts',
        'it-IT': 'Contatti'
      },
      texts: [
        {
          'en-EN':
            'To receive assistance, file complaints, or request information, the User may contact Hodlie at the following contacts:<ul><li><a href="' +
            telegramChannel +
            '" target="_blank" rel="noreferrer">Telegram</a></li><li><a href="mailto:hello@hodlie.net">hello@hodlie.net</a></li></ul>',
          'it-IT':
            'Per ricevere assistenza, presentare reclami o richiedere informazioni l’Utente può contattare Hodlie ai seguenti contatti:<ul><li><a href="' +
            telegramChannel +
            '" target="_blank" rel="noreferrer">Telegram</a></li><li><a href="mailto:hello@hodlie.net">hello@hodlie.net</a></li></ul>'
        }
      ]
    }
  ],
  ending: {
    'en-EN':
      'Pursuant to and for the purposes of Articles 1341 and 1342 of the Civil Code, the User declares that he/she understands and expressly accepts the following articles of the Terms and Conditions: 2 (Changes to T&C), 8.2. (Terms of Use and Delivery of the Service), 9 (User Representations and Warranties), 10 (Terms of Service Provision and Legal Warranty of Conformity), 13 (Withdrawal Right), 14 (Assignment of Contract).',
    'it-IT':
      'Ai sensi e per gli effetti degli articoli 1341 e 1342 del Codice Civile, l’Utente dichiara di aver compreso e di accettare espressamente i seguenti articoli delle Termini e Condizioni: 2 (Modifiche ai Termini e Condizioni), 8.2. (Condizioni di utilizzo e fornitura del Servizio), 9 (Dichiarazioni e garanzie dell’Utente), 10 (Condizioni di fornitura del Servizio e garanzia legale di conformità), 13 (Diritto di recesso), 14 (Cessione del Contratto).'
  },
  signature: {
    'en-EN': 'The Team of ' + name,
    'it-IT': 'Lo Staff di ' + name
  },
  download: {
    'en-EN': 'Download the document',
    'it-IT': 'Scarica il documento'
  },
  textSize: 'small',
  version: 1,
  lastUpdateDate: '21/08/2023'
};
