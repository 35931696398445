import axios from 'axios';
import { serverRoutes, internalRoutes } from 'router/routes';
import { endpointInterface as textLang } from 'staticData/languages';
import { sharedState } from 'staticData/common';
import { getFromCache, saveToCache, cleanCache } from 'services/cache';

export const endpointInterface = async (
  lang = 'en-EN',
  serverEndpoint,
  internalEndpoint,
  httpMethod,
  usingCredentials = false,
  params = {},
  getDataFromCache = false,
  saveDataToCache = getDataFromCache,
  cacheTime1 = 10, // Time in minutes,
  cacheTime2 = 10 // Time in minutes
) => {
  const envType = process.env.REACT_APP_ENV;
  const username = sharedState?.userInfos?.username || 'noUsername';
  // Get and set the cache
  // Create a unique key for the API call
  // Some additions fos DEV environment and support ->
  // -> if the environment is DEV, add an underscore to the key, add also username to the key
  const key = `${
    envType || '_'
  }${username}_${serverEndpoint}_${internalEndpoint}_${httpMethod}_${JSON.stringify(
    params
  )}`;

  // Clean the cache before using it
  await cleanCache();
  // Check if the response is in cache
  if (getDataFromCache) {
    const cacheData = await getFromCache(key, cacheTime1, cacheTime2);
    if (cacheData) return cacheData;
  }

  // Get the correct route
  const server = serverRoutes.children[serverEndpoint];
  const internal = internalRoutes.children[internalEndpoint];
  var response;
  try {
    // GET method: route, params (in js DICTIONARY), credentials (if necessary)
    if (httpMethod.toLowerCase() === 'get') {
      response = await axios.get(
        server.link + ':' + server.port + internal.path,
        { params: params, withCredentials: usingCredentials }
      );
    }
    // POST method: route, params (in js FORM), credentials (if necessary)
    else if (httpMethod.toLowerCase() === 'post') {
      response = await axios.post(
        server.link + ':' + server.port + internal.path,
        params,
        { withCredentials: usingCredentials }
      );
    }
  } catch (error) {
    // Return Error data
    if (error.response !== undefined) {
      return {
        validResponse: false,
        responseStatus: error.response.status,
        responseMessage:
          typeof error.response.data === 'object'
            ? error.response.data[lang]
            : error.response.data,
        errorID:
          typeof error.response.data === 'object' &&
          error.response.data.error_id
            ? error.response.data.error_id
            : null,
        data: {}
      };
    } else
      return {
        validResponse: false,
        responseStatus: '500',
        responseMessage: textLang.response[lang],
        data: {}
      };
  }
  // Convert response and return
  const outputData = response.data;
  // Store the response in cache only if backendCache if not null (as default)
  if (saveDataToCache) {
    await saveToCache(key, Date.now(), {
      validResponse: true,
      responseStatus: response.status,
      responseMessage: '',
      data: outputData
    });
  }

  return {
    validResponse: true,
    responseStatus: response.status,
    responseMessage: '',
    data: outputData
  };
};
