import axios from 'axios';
import { toast } from 'react-toastify';
import { errorMsg } from 'staticData/errorMsg';
import { serverRoutes } from 'router/routes';
import { getFromCache, saveToCache, cleanCache } from 'services/cache';
import { sharedState } from 'staticData/common';
import 'react-toastify/dist/ReactToastify.min.css';
import { internalRoutesV2 } from 'router/routes';

export const endpointInterfaceV2 = async ({
  serverEndpoint = 'backend',
  internalEndpoint,
  httpMethod,
  usingCredentials = false,
  pathParams = {},
  queryParams = {},
  bodyParams = {},
  showToast = true,
  getDataFromCache = false,
  saveDataToCache = getDataFromCache,
  cacheTime1 = 10, // Time in minutes
  cacheTime2 = 10, // Time in minutes
  showError = true
}) => {
  const { lang } = sharedState.config;
  const envType = process.env.REACT_APP_ENV;
  const username = sharedState?.userInfos?.username || 'noUsername';
  // Get the correct route
  const server = serverRoutes.children[serverEndpoint];
  // Create a copy of the internal route to avoid changing the original
  // don't use -> internal = internalRoutesV2[internalEndpoint] -> this will change the original object
  // (this is necessary to not replace path parameters)
  let internal = JSON.parse(JSON.stringify(internalRoutesV2[internalEndpoint]));

  // Get and set the cache
  // Create a unique key for the API call
  // Some additions fos DEV environment and support ->
  // -> if the environment is DEV, add an underscore to the key. Add also username to the key
  const key = `${
    envType || '_'
  }${username}_${serverEndpoint}_${internalEndpoint}_${httpMethod}_${JSON.stringify(
    pathParams
  )}_${JSON.stringify(bodyParams)}`;

  // Clean the cache before using it
  await cleanCache();
  // Check if the response is in cache
  if (getDataFromCache) {
    const cacheData = await getFromCache(key, cacheTime1, cacheTime2);
    if (cacheData) return cacheData;
  }

  const showToastError = (message, logError = false, errorInLog = '') => {
    const toastTime = 5000;
    if (logError) console.error(errorInLog || message);
    if (showToast && showError) {
      toast.error(message, { closeButton: false, autoClose: toastTime });
      setTimeout(() => {
        toast.dismiss();
      }, toastTime);
    }
  };

  // Check if server and internal exist
  if (!server || !internal) {
    let errorMessage = {
      'en-EN': `${!server ? 'Server' : 'Internal endpoint'} does not exist`,
      'it-IT': `${!server ? 'Il server' : "L'endpoint interno"} non esiste`
    };
    showToastError(errorMessage[lang], true);
    return {
      validResponse: false,
      responseStatus: 500,
      data: null
    };
  }

  // Check if the route is active
  if (!internal.active) {
    let errorMessage = {
      'en-EN': 'The route is not active',
      'it-IT': 'Il percorso non è attivo'
    };
    showToastError(errorMessage[lang], true);
    return {
      validResponse: false,
      responseStatus: 500,
      data: null
    };
  }

  // Replace path parameters
  for (let key in pathParams) {
    internal.path = internal.path.replace(`<${key}>`, pathParams[key]);
  }

  var response;
  try {
    // GET method: route, bodyParams (in path), credentials (if necessary)
    if (httpMethod.toLowerCase() === 'get') {
      response = await axios.get(
        server.link + ':' + server.port + internal.path,
        { params: queryParams, withCredentials: usingCredentials }
      );
    }
    // POST method: route, bodyParams (in json body), credentials (if necessary)
    else if (httpMethod.toLowerCase() === 'post') {
      response = await axios.post(
        server.link + ':' + server.port + internal.path,
        bodyParams,
        { withCredentials: usingCredentials }
      );
    }
    // PUT method: route, bodyParams (in json body), credentials (if necessary)
    else if (httpMethod.toLowerCase() === 'put') {
      response = await axios.put(
        server.link + ':' + server.port + internal.path,
        bodyParams,
        { withCredentials: usingCredentials }
      );
    }
    // DELETE method: route, credentials (if necessary)
    else if (httpMethod.toLowerCase() === 'delete') {
      response = await axios.delete(
        server.link + ':' + server.port + internal.path,
        { withCredentials: usingCredentials }
      );
    }
  } catch (error) {
    const responseData = error.response?.data;
    const isObject = typeof responseData === 'object';
    const errorID = isObject ? responseData.error_id : 'genericNotFound';
    showToastError(
      errorMsg?.[errorID]?.[lang] || errorMsg['genericNotFound'][lang],
      true,
      'Error during HTTP request:' + error
    );
    return {
      validResponse: false,
      errorID: errorID,
      data: null
    };
  }
  // Convert response and return
  if (response.status >= 200 && response.status < 300) {
    const outputData = {
      validResponse: true,
      responseStatus: response.status,
      responseMessage: '',
      data: response.data
    };

    // Store the response in cache only if backendCache if not null (as default)
    if (saveDataToCache) {
      await saveToCache(key, Date.now(), outputData);
    }

    return outputData;
  } else {
    showToastError(response.statusText);
    return {
      validResponse: false,
      responseStatus: response.status,
      responseMessage: response.statusText,
      data: null
    };
  }
};

/*
 Examples of usage:

GET request:

  const allocationData = async botID => {
    await endpointInterfaceV2({
      internalEndpoint: 'changeAllocation',
      httpMethod: 'get',
      usingCredentials: true,
      pathParams: {
        bot_id: botID
      },
      getDataFromCache: true
    });
  };


POST request:

  const allocationData = async botID => {
    await endpointInterfaceV2({
      internalEndpoint: 'changeAllocation',
      httpMethod: 'post',
      usingCredentials: true,
      pathParams: {
        bot_id: botID
      },
      bodyParams: {coins: {output: 0.5, input: 0.5}}
    });
  };


*/
