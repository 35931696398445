import { sharedState } from 'staticData/common';

export const getFromCache = (key, cacheTime = 10) => {
  let backendCache = sharedState.backendCache;
  if (backendCache && key in backendCache) {
    const cachedResponse = backendCache[key];
    if (cachedResponse) {
      const currentTime = Date.now();
      const elapsedTime = currentTime - cachedResponse.timestamp;

      // If the response is in cache and the timestamp is within a desired time interval, return the cached response
      if (elapsedTime < cacheTime * 60 * 1000) {
        return cachedResponse.data;
      }
    }
  }
  return null;
};

export const saveToCache = (key, ts = Date.now(), data) => {
  let backendCache = sharedState.backendCache;
  backendCache = {
    ...backendCache,
    [key]: {
      data: data,
      timestamp: ts
    }
  };
  // Update sharedState.backendCache
  sharedState.backendCache = backendCache;
};

export const cleanCache = (maxCacheTime = 30) => {
  let backendCache = sharedState.backendCache;
  const currentTime = Date.now();
  for (let key in backendCache) {
    const cachedResponse = backendCache[key];
    const elapsedTime = currentTime - cachedResponse.timestamp;
    if (elapsedTime >= maxCacheTime * 60 * 1000) {
      delete backendCache[key];
    }
  }
  sharedState.backendCache = backendCache;
};
